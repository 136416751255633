/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useState } from 'react'
import type { IProviderProps } from './provider.interface'

interface IContactFormContext {
  formVisibility: boolean
  toggleVisibility: () => void
}

const ContactFormContext = createContext<IContactFormContext>({
  formVisibility: false,
  toggleVisibility: () => { }
})

const ContactFormProvider: React.FC<IProviderProps> = (props) => {
  const [formVisibility, setFormVisibility] = useState(false)

  const toggleVisibility = (): void => {
    setFormVisibility(!formVisibility)
  }

  return (
    <ContactFormContext.Provider value={{ formVisibility, toggleVisibility }}>
      {props.children}
    </ContactFormContext.Provider>
  )
}

function useForm (): IContactFormContext {
  const context = useContext(ContactFormContext)

  if (!context) {
    throw new Error('Context must be used inside ContactFormProvider')
  }

  return context
}

export { ContactFormProvider, useForm }
