import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
  }

  div, h1, h2, h3, h4, h5, h6, p, button, aside, input, textarea{
    font-family: "Roboto Flex", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
      "slnt" 0,
      "wdth" 75,
      "GRAD" 0,
      "XOPQ" 96,
      "XTRA" 468,
      "YOPQ" 79,
      "YTAS" 854,
      "YTDE" -305,
      "YTFI" 738,
      "YTLC" 514,
      "YTUC" 712;
  }

  body{
    background: #fff;
    color: #000;

    margin: 0px;
  }

  h1, h2, h3, h4, h5, h6{
    font-weight: 900;
    margin: 0px 0 20px;
  }

  // <uniquifier>: Use a unique and descriptive class name
  // <weight>: Use a value from 100 to 1000
  // <width>: Use a value from 25 to 151
  // <slant>: Use a value from -10 to 0
  // <grade>: Use a value from -200 to 150

  /* .roboto-flex-titles {
    font-family: "Roboto Flex", sans-serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
    font-variation-settings:
      "slnt" <slant>,
      "wdth" <width>,
      "GRAD" <grade>,
      "XOPQ" 96,
      "XTRA" 468,
      "YOPQ" 79,
      "YTAS" 854,
      "YTDE" -305,
      "YTFI" 738,
      "YTLC" 514,
      "YTUC" 712;
  } */

  // <uniquifier>: Use a unique and descriptive class name
  // <weight>: Use a value from 100 to 900
  // <width>: Use a value from 50 to 150
  // <grade>: Use a value from -50 to 100

  /* .roboto-serif-<uniquifier> {
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
    font-variation-settings:
      "wdth" <width>,
      "GRAD" <grade>;
  } */
`

export const colors = {
  blue: '#489ec7',
  pink: '#9d3495',
  blueGradient: 'linear-gradient( 121deg, rgba(34,118,205,1) 0%, rgba(88,169,211,1) 100% )',
  pinkGradient: 'linear-gradient(140deg,#a5349c,#b776b2)',
  mixedGradient: 'linear-gradient(104deg,#af30a6,#489ec7)'
}

export default GlobalStyle
