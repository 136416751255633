import React, {
  useEffect, useRef, useState
} from 'react'
import { Form } from '@unform/web'
import { type FormHandles } from '@unform/core'
import * as Yup from 'yup'
import axios from 'axios'

import getValidationErrors from '../../utils/getValidationErrors'

import Input from '../Input'
import Textarea from '../Textarea'
import { FormContainer, GoBackButton } from '../../styles/AppStyles'
import { useQuoteData } from '../../hooks/quoteData'
import QuoteFinish from '../QuoteFinish'
import { useToast } from '../../hooks/toast'
import MaskedInput from '../Input/MaskedInput'

import {
  CEPContainer, DocumentContainer, Toggle, ToggleLabel
} from './styles'

interface IPersonalFormProps {
  isVisible: boolean
  backToTranslation: () => void
  backToRevision: () => void
  backToTimebased: () => void
  backToBegining: () => void
}

interface SubmittedData {
  name: string
  phone: string
  email: string
  company: string
  cnpj: string
  cpf: string
  cep: string
  message: string
}

interface CepApi {
  cep: string
  city: string
  neighborhood: string
  state: string
  street: string
}

const FormPersonalData: React.FC<IPersonalFormProps> = ({
  isVisible,
  backToTranslation,
  backToRevision,
  backToTimebased,
  backToBegining
}) => {
  const { setPersonalData, serviceData } = useQuoteData()
  const [buttonActive, setButtonActive] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [address, setAddress] = useState<string>('preencha o CEP')
  const formRef = useRef<FormHandles>(null)
  const { addToast } = useToast()

  const handleGoBack = (): void => {
    if (serviceData?.selectedService === 'translation') {
      backToTranslation()
      return
    }

    if (serviceData?.selectedService === 'revision') {
      backToRevision()
      return
    }

    if (serviceData?.selectedService === 'subtitling' || serviceData?.selectedService === 'transcription') {
      backToTimebased()
      return
    }

    backToBegining()
  }

  const handleUpdate = async (): Promise<void> => {
    if (formRef.current) {
      formRef.current?.setErrors({})

      try {
        const formData = (formRef.current.getData() as SubmittedData)

        const schema = Yup.object().shape({
          name: Yup.string().required('este campo é obrigatório'),
          phone: Yup.string().required('este campo é obrigatório').matches(/(\+\d{2}\s?)?(\(?\d{2}\)?\s?)?(\d{4,5}-?\s?\d{4})|(\+\d{2}\s?)?(\(?\d{2}\)?\s?)?(\d{3}-?\s?\d{3}-?\s?\d{3})/, 'use apenas números'),
          email: Yup.string().email('insira um email válido').required('este campo é obrigatório'),
          cpf: Yup.string().matches(/([0-9]{3}[.][0-9]{3}[.][0-9]{3}[-][0-9]{2})/, 'formato 999.999.999-99'),
          cnpj: Yup.string().matches(/([0-9]{2}[.][0-9]{3}[.][0-9]{3}[/][0-9]{4}[-][0-9]{2})/, 'formato 11.222.333/0000-00'),
          company: Yup.string(),
          cep: Yup.string().required('este campo é obrigatório').matches(/([0-9]{5})-([0-9]{3})/, 'CEP inválido'),
          message: Yup.string()
        })

        await schema.validate(formData, {
          abortEarly: false
        })

        let cnpjcpf: string
        if (toggle) {
          cnpjcpf = formData.cnpj.replace(/\.|-|\//g, '')
        } else {
          cnpjcpf = formData.cpf.replace(/\.|-|\//g, '')
        }

        setPersonalData({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          cep: formData.cep,
          cnpjcpf,
          company: formData.company,
          message: formData.message
        })
        setButtonActive(true)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
          setButtonActive(false)
          return
        }
        setButtonActive(false)
        addToast({
          title: 'Ocorreu um erro',
          message: 'tente novamente',
          type: 'error'
        })
      }
    }
  }

  const handleGetAddress = async (cep: string): Promise<void> => {
    const cepNumbers = cep.replace(/_|-/g, '')
    if (cepNumbers.length === 8) {
      try {
        setAddress('buscando CEP...')
        const result = await axios.get<CepApi>(`https://brasilapi.com.br/api/cep/v2/${cepNumbers}`)
        setAddress(`${result.data.street}, ${result.data.neighborhood} - ${result.data.city}, ${result.data.state}`)
      } catch {
        addToast({
          title: 'CEP não encontrado',
          message: 'confira os números',
          type: 'info'
        })
        setAddress('CEP não encontrado')
      }
    } else {
      setAddress('preencha o CEP')
    }
    await handleUpdate()
  }

  const handleToggle = (): void => {
    setToggle(!toggle)
  }

  useEffect(() => {
    const runUpdate = async (): Promise<void> => {
      await handleUpdate()
    }
    runUpdate().catch(console.error)
  }, [toggle])

  return (
    <FormContainer isVisible={isVisible}>
      <GoBackButton onClick={handleGoBack}>Voltar</GoBackButton>
      <h1>Seus dados</h1>
      <p>preencha os campos abaixo para receber o orçamento.</p>
      <Form ref={formRef} onSubmit={() => { }} placeholder={''} onPointerEnterCapture={''} onPointerLeaveCapture={''}>
        <Input name="name" placeholder="Nome" onChange={handleUpdate} />
        <Input name="phone" placeholder="Telefone" onChange={handleUpdate} />
        <Input name="email" placeholder="E-Mail" type="email" onChange={handleUpdate} />
        <DocumentContainer>
          <ToggleLabel isOn={!toggle}>Pessoa Física</ToggleLabel>
          <Toggle isOn={toggle} onClick={handleToggle} />
          <ToggleLabel isOn={toggle}>Empresa</ToggleLabel>
          {toggle
            ? (
              <MaskedInput name="cnpj" placeholder="CNPJ" mask="99.999.999/9999-99" onChange={handleUpdate} />
            )
            : (
              <MaskedInput name="cpf" placeholder="CPF" mask="999.999.999-99" onChange={handleUpdate} />
            )}
        </DocumentContainer>
        {toggle && (
          <Input name="company" placeholder="Nome da Empresa" onChange={handleUpdate} />
        )}
        <CEPContainer>
          <MaskedInput name="cep" placeholder="CEP" mask="99999-999" onChange={async (e) => { await handleGetAddress(e.target.value) }} />
          <aside>{address}</aside>
        </CEPContainer>
        <Textarea name="message" placeholder="Informações para o tradutor" onChange={handleUpdate} />

        <QuoteFinish isActive={buttonActive} />
      </Form>

    </FormContainer>
  )
}

export default FormPersonalData
