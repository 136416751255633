import styled, { css } from 'styled-components'
import { colors } from 'styles/GlobalStyles'

interface IContainer {
  isVisible: boolean
}

export const Container = styled.div<IContainer>`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #489ec7e6;
  z-index: 12;
  padding: 100px 0;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  ${(props) => props.isVisible && css`
    display: flex;
    overflow-y: scroll;
  `}

  @media print{
    padding: 0px;
  }
`

export const Wrapper = styled.div`
  position: relative;
  width: 810px;
  background: #fff;
  padding: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;

  header{
    width: 100%;
    color: ${colors.blue};
    font-size: 18px;
    line-height: 1.5em;
    margin: 0 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div p{
      text-align: right;
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  p{
    margin: 0;
    text-align: left;
    max-width: 100%;
  }

  h2{
    font-weight: 900;
    margin: 16px 0 6px;
    font-size: 21px;
    letter-spacing: 2px;
    color: #444;
  }

  h3{
    font-weight: 400;
    font-size: 18px;
  }

  h4{
    justify-self: flex-end;
    margin: 30px 0 0 0;
    color: ${colors.blue};
    font-size: 20px;
    font-weight: 500;
  }

  @media print{
    width: 690px;
    padding: 30px 0 0 0;

    header div p{
      font-size: 14px;
    }
  }
`

export const Logo = styled.img`
  width: 300px;

  @media print{
    width: 250px;
  }
`

export const Shape = styled.img`
  position: absolute;
  bottom: 0;
  right: -1px;
  width: 200px;
  transform: rotateZ(90deg);

  @media print{
    display: none;
  }
`

export const PDFQuoteButtons = styled.div`
  width: 810px;
  margin-bottom: 20px;

  button{
    border: none;
    outline: none;
    background: #fff;
    padding: 12px 26px;
    margin-right: 20px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: ${colors.blue};
    cursor: pointer;
  }

  @media print{
    display: none;
  }
`
