import React, { useEffect } from 'react'
import {
  FiAlertCircle, FiXCircle, FiCheckCircle, FiInfo
} from 'react-icons/fi'
import { Container } from './styles'
import { useToast } from '../../../hooks/toast'

interface ToastProps {
  message: ToastMessage
  style: object
}

interface ToastMessage {
  id: string
  type?: 'info' | 'success' | 'error'
  title: string
  message?: string
}

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />
}

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast()

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id)
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [removeToast, message.id])

  return (
    <Container type={message.type} hasdescription={Number(!!message.message)} style={style}>
      {icons[message.type ?? 'info']}

      <div>
        <strong>{message.title}</strong>
        {message.message && <p>{message.message}</p>}
      </div>

      <button onClick={() => { removeToast(message.id) }} type="button">
        <FiXCircle size={21} />
      </button>
    </Container>
  )
}

export default Toast
