import styled from 'styled-components'
import { colors } from 'styles/GlobalStyles'

export const Container = styled.div`
  width: 90%;
  max-width: 930px;
  margin: 0 auto;
  padding: 80px 0 110px;
  display: flex;
  justify-content: center;
  column-gap: 50px;

  @media(max-width: 750px){
    & > div:first-child{ width: 40%; border-radius: 0px; }
    & > div:last-child{ width: 60%; }
  }

  @media(max-width: 530px){
    flex-direction: column;
    & > div:first-child{ width: 100%; }
    & > div:last-child{ width: 100%; }
  }
`

export const Column = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  row-gap: 20px;
  width: ${props => props.width};

  @media(max-width: 530px){
    width: 100%;
  }
`

export const Heading = styled.h1`
  margin: 0;
  font-size: 38px;
  line-height: 1.1em;
  letter-spacing: 0px;
  font-weight: 800;
  font-variation-settings: "slnt" 0, "wdth" 83, "GRAD" -100;

  @media(max-width: 930px){
    font-size: 24px;
  }
`

export const Title = styled.h2`
  margin: 0px 0;
  font-family: "Roboto Serif", serif;
  font-size: 30px;
  font-weight: 550;
  font-style: italic;
  font-variation-settings: "wdth" 99, "GRAD" -50;
`

export const Text = styled.p`
  font-size: 20px;
  font-weight: 500;
  font-variation-settings: "slnt" 0,"wdth" 120,"GRAD" 0;
  margin: 0;
  line-height: 1.2em;

  @media(max-width: 930px){
    font-size: 15px;
  }
`

export const BoxText = styled.p`
  margin: 40px auto;
  text-align: center;
  font-weight: 350;
  font-size: 17px;
  font-variation-settings: "slnt" 0, "wdth" 150, "GRAD" 40;

  border: 10px solid ${colors.pink}80;
  padding: 16px 24px;
  max-width: 700px;
  letter-spacing: .6px;
`

export const QuarterMoonImage = styled.div<{ url: string }>`
  background-position: center top;
  background-size: cover;
  background-image: linear-gradient(17deg,rgba(183,118,178,.34),rgba(164,175,192,.45)), url(${props => props.url});
  height: 100%;
  border-bottom-left-radius: 100%;

  @media(max-width: 750px){
    border-bottom-left-radius: 0;
  }

  @media(max-width: 530px){
    height: 85vw;
    border-bottom-left-radius: 100%;
  }
`

export const KonektoDot = styled.img`
  max-width: 20px;
  transform: rotate(90deg);
`
