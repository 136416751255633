import React from 'react'
import { useForm } from 'hooks/contactForm'
import { Container } from './styles'

const Component: React.FC = () => {
  const { toggleVisibility } = useForm()
  return (
    <Container>
      <h3>Dimensione o seu projeto em instantes através do <em>Orçamento Rápido</em> ou se preferir <em>entre em contato</em> com os nossos especialistas.</h3>
      <div>
        <button onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}>fazer orçamento</button>
        <button onClick={toggleVisibility}>entre em contato</button>
      </div>
    </Container>
  )
}

export default Component
