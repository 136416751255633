import React from 'react'
import { Container } from './styles'

interface Props {
  title: string
  children?: React.ReactNode
  className?: string
}

const Tooltip: React.FC<Props> = ({ title, children, className = '' }) => (
  <Container className={className}>
    {children}
    <span>{title}</span>
  </Container>
)

export default Tooltip
