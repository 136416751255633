import React from 'react'
import { Form } from '@unform/web'

import { useToast } from '../../hooks/toast'
import { useCoupon } from '../../hooks/coupon'
import Input from '../Input'

import { CupomContainer } from './styles'

const Cupom: React.FC = () => {
  const { addToast } = useToast()
  const { couponData: coupon, setCouponData } = useCoupon()

  const handleSubmit = (data: { cupom: string }): void => {
    if (!process.env.REACT_APP_CUPOM) {
      addToast({
        title: 'Erro ao processar o cupom.',
        type: 'error'
      })
      return
    }

    if (data.cupom === process.env.REACT_APP_CUPOM) {
      setCouponData({ code: data.cupom, percent: 10 })

      addToast({
        title: 'Cupom ativado!',
        type: 'success'
      })
    } else {
      setCouponData({ code: data.cupom })

      addToast({
        title: 'Código de cupom inválido.',
        type: 'error'
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit} placeholder={''} onPointerEnterCapture={''} onPointerLeaveCapture={''}>
      <CupomContainer>
        <Input
          name="cupom"
          label="Cupom"
          tooltip="Nossos cupons tem validade e custo mínimo. Em caso de dúvidas, confira os dados da promoção."
          placeholder="Código"
          defaultValue={coupon?.code}
        />
        <Input name="submit" type="submit" value="Ativar Cupom" />
      </CupomContainer>
    </Form>
  )
}

export default Cupom
