import styled, { css } from 'styled-components'
import Tooltip from '../Tooltip'

interface inputProps {
  isErrored: boolean
  maxWidth?: number
}

export const Container = styled.div<inputProps>`
  width: 100%;
  ${(props) => props.maxWidth && css`
    max-width: ${props.maxWidth}%;
  `}

  textarea{
    margin-bottom: 17px;
    padding: 9px 25px;
    border: 1px #000;
    border-radius: 20px;
    width: 100%;
    color: #1c5ab6;
    font-size: 16px;
    line-height: 1.4em;
    min-height:130px;

    &:focus{
      outline: none;
    }

    &::placeholder{
      color: #aeb8c8;
    }
  }

  aside {
    color: #fff;
    font-size: 14px;
    padding-left: 15px;
    margin-bottom: 4px;
    display: flex;

    div{
      margin-left: 5px;
    }
  }

  ${(props) => props.isErrored && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}

  @media(max-width: 550px){
    max-width: 100%;
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  margin-bottom: 17px;

  svg{
    margin-right: 0;
  }

  span{
    background: #c53030;
    color: #fff;

    &::before{
      border-color: #c53030 transparent;
    }
  }
`
