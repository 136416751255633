import styled from 'styled-components'

export const Container = styled.div`
    background: #000;
    color: #fff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: center;
    justify-content: center;
    padding: 15px 20px;

    h3{
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    a{
        color: #fff;
    }
`
