import styled from 'styled-components'
import { colors } from 'styles/GlobalStyles'

export const Wrapper = styled.section`
  padding: 0px 0;
`

export const Heading = styled.h2`
  max-width: 90%;
  margin: 0 auto;
  text-align: center;

  font-size: 70px;
  font-weight: 700;
  font-variation-settings: "slnt" 0,"wdth" 61,"GRAD" -100;

  @media(max-width: 980px){
    font-size: 50px;
  }

  @media(max-width: 600px){
    font-size: 35px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  width: 90%;
  max-width: 1000px;
  margin: 20px auto 0;
  gap: 20px;

  @media(max-width: 980px){
    flex-wrap: wrap;
  }

  @media(max-width: 600px){
    width: 96%;
    margin-top: 20px;
  }
`

export const CaseContainer = styled.div`
  width: 100%;
  padding: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  border: 24px solid #2dc5d55c;

  figure {
    background-color: #000000;
    padding: 10px 13px 7px;
    margin: 0;
  }
  
  img{
    max-width: 260px;
    max-height: 70px;
  }

  div{
    width: 50%;

    span{
      width: 100%;
      display: block;
      font-size: 10px;
      letter-spacing: .5em;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
  }

  h3{
    font-size: 21px;
    font-weight: 800;
    line-height: 1.1em;
    margin: 10px 0 0;

    em{
      color: ${colors.pink};
      font-style: unset;
    }
  }

  p{
    margin: 0;
    font-size: 15px;
    line-height: 1.3em;
    letter-spacing: .05em;
  }
  
  @media(max-width: 600px){
    padding: 20px;

    h3{
      font-size: 17px;
      line-height: 1.2em;
    }

    p{
      font-size: 14px;
    }

    figure{
      width: 100%;
    }

    img{
      width: 100%;
    }
  }
`
