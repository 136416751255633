import React, { useRef, useState } from 'react'

import { Form } from '@unform/web'
import { type FormHandles } from '@unform/core'
import * as yup from 'yup'

import { useQuoteData } from '../../hooks/quoteData'
import allLanguages from '../../helpers/languages'
import TextbasedProducts from '../TextbasedProducts'

import Upload from '../Upload'
import Input from '../Input'
import Select from '../Select'
import Cupom from '../Cupom'
import { FormContainer, GoBackButton, ExternalLink } from '../../styles/AppStyles'
import { Container } from './styles'

interface IServiceFormProps {
  isVisible: boolean
  toggleVisibility: () => void
  goBackButton: () => void
}

interface IFormData {
  languageMatrix: string
  languageFinal: string
  numberOfWords: number
}

const TranslationDocument: React.FC<IServiceFormProps> = ({
  isVisible,
  toggleVisibility,
  goBackButton
}) => {
  const { serviceData, setServiceData, setServiceTotals } = useQuoteData()
  const formRef = useRef<FormHandles>(null)
  const [servicesActive, setServicesActive] = useState(false)

  const handleUpdate = async (): Promise<void> => {
    setServicesActive(false)
    if (formRef.current && serviceData) {
      const formData = (formRef.current.getData() as IFormData)

      const matrixData = formData.languageMatrix.split(',')
      const matrixNumber = Number(matrixData[0])
      const matrixLanguage = matrixData[1]

      const finalData = formData.languageFinal.split(',')
      const finalNumber = Number(finalData[0])
      const finalLanguage = finalData[1]

      let selectedGroup: number
      if (matrixNumber >= finalNumber) {
        selectedGroup = matrixNumber
      } else {
        selectedGroup = finalNumber
      }

      const sanitizedNumberOfWords = Number(formData.numberOfWords.toString().replace(/\./g, ''))

      let languageIsPtbr = false
      if (matrixLanguage === 'Português BR' || finalLanguage === 'Português BR') {
        languageIsPtbr = true
      }

      setServiceData({
        languageFinal: finalLanguage,
        languageMatrix: matrixLanguage,
        languageIsPtbr,
        numberOfWords: sanitizedNumberOfWords,
        languageGroup: selectedGroup,
        selectedService: serviceData.selectedService,
        service: '',
        files: serviceData.files
      })

      const schema = yup.object().shape({
        numberOfWords: yup.number().required(),
        languageMatrix: yup.string().required(),
        languageFinal: yup.string().required()
      })

      try {
        await schema.validate(formData)
        setServicesActive(true)
      } catch {
        setServicesActive(false)
      }
    }
  }

  const handleGoBack = (): void => {
    if (formRef.current) {
      formRef.current.reset()
      setServiceData({
        languageFinal: '',
        languageMatrix: '',
        numberOfWords: undefined,
        service: '',
        languageGroup: 0,
        files: serviceData?.files
      })
      setServiceTotals({
        totalTime: undefined,
        totalCost: undefined
      })
      setServicesActive(false)
      goBackButton()
    }
  }

  return (
    <FormContainer isVisible={isVisible}>
      <GoBackButton onClick={handleGoBack}>Voltar</GoBackButton>
      <Form ref={formRef} onSubmit={() => { }} placeholder={''} onPointerEnterCapture={''} onPointerLeaveCapture={''}>
        <Container>
          <Upload />

          <section>
            <Input
              name="numberOfWords"
              type="number"
              placeholder="apenas números"
              label="Total de palavras"
              onChange={handleUpdate}
              maxWidth={32}
            />

            <Select name="languageMatrix" onChange={handleUpdate} label="Língua original" maxWidth={33}>
              <option value="">Selecione</option>
              {allLanguages.map((language) => (
                <option
                  key={`${language.name}-matrix`}
                  value={[language.group.toString(), language.name]}
                >
                  {language.name}
                </option>
              ))}
            </Select>

            <Select name="languageFinal" onChange={handleUpdate} label="Língua final" maxWidth={33}>
              <option value="">Selecione</option>
              {allLanguages.map((language) => (
                <option
                  key={language.name}
                  value={[language.group.toString(), language.name]}
                >
                  {language.name}
                </option>
              ))}
            </Select>
          </section>
        </Container>
      </Form>

      <Cupom />

      {servicesActive && (
        <>
          <TextbasedProducts
            whenSelected={toggleVisibility}
          />
          <ExternalLink href="https://www.konekto.me/contato" target="_blank" rel="noreferrer">
            Precisa da entrega em um prazo inferior ao calculado? Entre em contato com nossa equipe de vendas!
          </ExternalLink>
        </>
      )}

    </FormContainer>
  )
}

export default TranslationDocument
