import styled, { css } from 'styled-components'
import { colors } from 'styles/GlobalStyles'

interface UploadProps {
  isDragActive: boolean
  isDragReject: boolean
  refKey?: string
  [key: string]: any
  type?: 'error' | 'success' | 'default'
}

const dragActive = css`
  border-color: #12a454;
`

const dragReject = css`
  border-color: #e83f5b;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  margin-bottom: 17px;

  > aside {
    color: #fff;
    font-size: 14px;
    padding-left: 15px;
    margin-bottom: 4px;
  }

  > main{
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  @media(max-width: 550px){
    > main{
      flex-wrap: wrap;
    }
  }
`

export const DropContainer = styled.div<UploadProps>`
  width: 48%;
  display: flex;
  flex-direction: center;
  align-items: center;
  border: 1.5px solid #fff;
  border-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  outline: none;

  transition: all 0.3s;

  ${props => props.isDragActive && dragActive}
  ${props => props.isDragReject && dragReject}

  &:hover{
    background-color: #ffffff14;
  }

  @media(max-width: 550px){
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
  }
`

const messageColors = {
  default: '#5636D3',
  error: '#e83f5b',
  success: '#12a454'
}

interface UploadMessageProps {
  type: 'error' | 'success' | 'default'
}

export const UploadMessage = styled.p<UploadMessageProps>`
  display: flex;
  line-height: 1.2em;
  margin-bottom: 0px!important;
  font-size: 17px;
  padding: 16px;
  font-weight: 700;
  color: #fff!important;

  ${props => props.type && css`
    color: ${messageColors[props.type]};
  `}

  justify-content: center;
  align-items: center;

  @media(max-width: 550px){
    font-size: 13px;
    font-weight: 400;
  }
`

export const UploadCounter = styled.div`
  width: 4%;
  position: relative;
  color: #fff;
  border: 1px solid #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px 0 2px;
  margin: 0 0 0 5px;
  font-weight: 700;

  aside{
    display: none;
    font-weight: 400;
    background-color: ${colors.pink};
    position: absolute;
    text-align: center;
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 30px;
    width: 180px;

    top: -30px;
    transform: translateX(0%);

    &::before{
      content: '';
      border-style: solid;
      border-color: ${colors.pink} transparent;
      border-width: 6px 6px 0 6px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover aside{
    display: block;
  }

  @media(max-width: 550px){
    display: none;
    width: 10%;
    margin-left: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 10px;
  }
`
