import axios from 'axios'

console.log(process.env.REACT_APP_NEW_ORDER_URI)
console.log(process.env.REACT_APP_CONTACT_EMAIL_URI)

const newOrder = axios.create({
  baseURL: process.env.REACT_APP_NEW_ORDER_URI
})

const contactEmail = axios.create({
  baseURL: process.env.REACT_APP_CONTACT_EMAIL_URI
})

const fileUpload = axios.create({
  baseURL: process.env.REACT_APP_FILE_UPLOAD_URI
})

const apiEndpoints = {
  newOrder,
  contactEmail,
  fileUpload
}

export default apiEndpoints
