import React from 'react'
import { Container } from './styles'

const ContactInfo: React.FC = () => (
  <Container>
    {/* <h3>
      veja as
      {' '}
      <a href="https://www.konekto.me/perguntas-frequentes" target="_blank" rel="noreferrer">Perguntas Frequentes</a>
      {' '}
      ou fale com nosso time de vendas: oi@konekto.me | +55 11 3280 4464
    </h3> */}
    <h3>
      fale com nosso time de vendas: oi@konekto.me | +55 11 3280 4464
    </h3>
  </Container>
)

export default ContactInfo
