import React, { useCallback, useEffect, useState } from 'react'
import { FiClock, FiInfo } from 'react-icons/fi'
import { type IconType } from 'react-icons'

import { formatCurrency } from '../../helpers/numberFormat'
import { useCoupon } from '../../hooks/coupon'
import { useQuoteData } from '../../hooks/quoteData'
import { calculateMinuteCost } from '../../helpers/quoteHelper'
import { TimebasedServices } from '../../helpers/services'

import Tooltip from '../Tooltip'
import {
  Container, ProductCard, DiscountTag, PriceCut
} from './styles'

interface IComponentProps {
  whenSelected: () => void
}

interface IProductCards {
  key: string
  name: string
  ref: string
  fullCost: number
  finalCost: number
  time: number
  description?: string
  discount?: number
  benefits?: Array<{
    benefit: string
    icon: IconType
  }>
  isSelected: boolean
}

const TimebasedProducts: React.FC<IComponentProps> = ({
  whenSelected
}) => {
  const { serviceData, setServiceData, setServiceTotals } = useQuoteData()
  const { couponData } = useCoupon()
  const [productCards, setProductCards] = useState<IProductCards[]>()

  function generateProductCards (totalMinutes: number, languageGroup: number, discount: number = 0): void {
    if (serviceData?.selectedService) {
      const options = TimebasedServices[serviceData.selectedService]
      const parsedOptions = options.map((option) => {
        const [cost, discountedCost, time] = calculateMinuteCost({
          languageGroup,
          totalMinutes,
          serviceName: option.value,
          languageIsPtbr: serviceData.languageIsPtbr,
          discount
        })
        if (cost === 0 && discountedCost === 0 && time === 0) {
          return {
            key: `${option.value}-${cost}`,
            name: option.name,
            ref: option.ref,
            fullCost: cost,
            finalCost: discountedCost,
            time,
            isSelected: false,
            description: 'não foi possível cotar esse serviço. Por gentileza faça sua solicitação por email.'
          }
        }
        return {
          key: `${option.value}-${cost}`,
          name: option.name,
          ref: option.ref,
          fullCost: cost,
          finalCost: discountedCost,
          time,
          discount,
          isSelected: false,
          description: option.description,
          benefits: option.benefits
        }
      })

      setProductCards(parsedOptions)
      setServiceData({ ...serviceData, service: '' })
      setServiceTotals({ totalCost: undefined, totalTime: undefined })
    }
  }

  const handleClick = useCallback((prod: IProductCards) => {
    setServiceData({ ...serviceData, service: prod.name, ref: prod.ref })
    setServiceTotals({ totalCost: prod.finalCost, totalTime: prod.time })
    whenSelected()
  }, [serviceData])

  useEffect(() => {
    if (serviceData?.totalMinutes && serviceData.languageGroup) {
      if (couponData?.percent && couponData.percent < 10 && serviceData.languageMatrix === serviceData.languageFinal) {
        generateProductCards(serviceData.totalMinutes, serviceData.languageGroup, 10)
      } else {
        generateProductCards(serviceData.totalMinutes, serviceData.languageGroup, couponData?.percent)
      }
    } else {
      setProductCards(undefined)
    }
  }, [serviceData?.languageFinal, serviceData?.languageMatrix, serviceData?.totalMinutes, couponData?.percent])

  return (
    <Container>
      {productCards?.map((product) => (
        <ProductCard
          key={product.key}
          isSelected={product.isSelected}
          onClick={() => { handleClick(product) }}
        >
          <h1>{product.name}</h1>
          {product.benefits?.map((benefit, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${product.key}-benefit-${index}`}>
              {benefit.icon({})}
              {' '}
              {benefit.benefit}
            </span>
          ))}
          {product.time > 0 && (
            <span>
              <FiClock />
              prazo estimado:
              {' '}
              {product.time}
              {' '}
              dias úteis
              <Tooltip title="o prazo de entrega dos pedidos feitos após as 16h são contados a partir do dia seguinte." className="deadlineTooltip">
                <FiInfo size={12} />
              </Tooltip>
            </span>
          )}
          {product.description && (
            <p>{product.description}</p>
          )}
          {product.finalCost > 0 && (
            <h3>custo total:</h3>
          )}
          {product.finalCost !== product.fullCost && (
            <PriceCut>
              <h2 className="price-cut">
                R$
                {formatCurrency(product.fullCost)}
              </h2>
              <DiscountTag>
                -
                {product.discount}
                %
              </DiscountTag>
            </PriceCut>
          )}
          {product.finalCost > 0 && (
            <>
              <h2>
                R$
                {formatCurrency(product.finalCost)}
              </h2>
              <div>peça agora</div>
            </>
          )}
        </ProductCard>
      ))}
    </Container>
  )
}

export default TimebasedProducts
