import React from 'react'

import { useForm } from 'hooks/contactForm'
import Logo from '../../images/logo-horizontal-preta.png'
import { Container, Navbar, Cover } from './styles'

const Header: React.FC = () => {
  const { toggleVisibility } = useForm()

  return (
    <Container>
      <Navbar>
        <a href="https://www.konekto.me"><img src={Logo} alt="Konekto" /></a>
        <div>
          <button onClick={toggleVisibility}>Contato</button>
          <a rel="noreferrer" href="tel:+551132804464" className="phone">+55 11 3280 4464</a>
        </div>
      </Navbar>

      <Cover>
        <h1>A sua central de projetos de tradução</h1>
      </Cover>
    </Container>
  )
}

export default Header
