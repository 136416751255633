import { type ValidationError } from 'yup'

type Errors = Record<string, string>

export default function getValidationErrors (err: ValidationError): Errors {
  const validation: Errors = {}

  err.inner.forEach((error) => {
    if (!error.path) {
      return
    }
    validation[error.path] = error.message
  })

  return validation
}
