import styled from 'styled-components'

export const Container = styled.div`
  position: relative;

  span{
    width: 180px;
    background: rgb(197, 51, 51);
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    opacity: 0;
    transition: opacity .4s;
    visibility: hidden;
    text-align: center;

    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);

    &::before{
      content: '';
      border-style: solid;
      border-color: rgb(197, 51, 51) transparent;
      border-width: 6px 6px 0 6px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover span{
    opacity: 1;
    visibility: visible;
  }
`
