/* eslint-disable no-unused-vars */
import React, {
  createContext, useContext, useMemo, useState
} from 'react'
import type { IProviderProps } from './provider.interface'

interface IQuoteContext {
  personalData: IPersonalData | undefined
  serviceData: IServiceData | undefined
  setPersonalData: (data: IPersonalData) => void
  setServiceData: (data: IServiceData) => void

  serviceTotals: IServiceTotals | undefined
  setServiceTotals: (totals: IServiceTotals) => void

  clearAllData: () => void
}

interface IPersonalData {
  name: string
  phone: string
  email: string
  company?: string
  cnpjcpf: string
  cep: string
  message?: string
}

export interface IServiceData {
  service?: string
  ref?: string
  languageMatrix?: string
  languageFinal?: string
  languageIsPtbr?: boolean
  languageGroup?: number
  numberOfWords?: number
  totalMinutes?: number
  selectedService?: string
  files?: Array<{
    filename: string
    content: string
  }>
}

interface IServiceTotals {
  totalCost?: number
  totalTime?: number
}

const QuoteContext = createContext<IQuoteContext>({
  personalData: undefined,
  serviceData: undefined,
  serviceTotals: undefined,
  setPersonalData: (data: IPersonalData) => { },
  setServiceData: (data: IServiceData) => { },
  setServiceTotals: (totals: IServiceTotals) => { },
  clearAllData: () => { }
})

const QuoteProvider: React.FC<IProviderProps> = ({ children }) => {
  const [personalData, setPersonalData] = useState<IPersonalData>()
  const [serviceData, setServiceData] = useState<IServiceData>()
  const [serviceTotals, setServiceTotals] = useState<IServiceTotals>()

  const clearAllData = (): void => {
    setPersonalData(undefined)
    setServiceData(undefined)
    setServiceTotals(undefined)
  }

  const MemoQuoteData = useMemo(() => ({
    setPersonalData,
    personalData,
    setServiceData,
    serviceData,
    setServiceTotals,
    serviceTotals,
    clearAllData
  }), [personalData, serviceData, serviceTotals])

  return (
    <QuoteContext.Provider value={MemoQuoteData}>
      {children}
    </QuoteContext.Provider>
  )
}

function useQuoteData (): IQuoteContext {
  const context = useContext(QuoteContext)

  if (!context) {
    throw new Error('Context must be used inside QuoteProvider')
  }

  return context
}

export { QuoteProvider, useQuoteData }
