import React, { useEffect, useRef } from 'react'
import ReactInputMask, { type Props } from 'react-input-mask'
import { useField } from '@unform/core'
import { FiAlertCircle } from 'react-icons/fi'

import Tooltip from '../../Tooltip'
import { Container, Error } from '../styles'

interface InputProps extends Props {
  name: string
  label?: string
  tooltip?: string
  maxWidth?: number
}

const MaskedInput: React.FC<InputProps> = ({
  name, label, tooltip, maxWidth, ...rest
}) => {
  const inputRef = useRef(null)
  const {
    fieldName, defaultValue, registerField, error
  } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Container isErrored={!!error} maxWidth={maxWidth}>
      {label && (
        <aside>
          {label}
          {tooltip && (
            <Tooltip title={tooltip}><FiAlertCircle size={14} /></Tooltip>
          )}
        </aside>
      )}
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53333" size={20} />
        </Error>
      )}
    </Container>
  )
}

export default MaskedInput
