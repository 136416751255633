import React from 'react'

import { formatNumber } from '../../helpers/numberFormat'

import konektoLogo from '../../images/logo-quebrada-verde.png'
import formaVerde from '../../images/forma-tr-verde.png'

import {
  Container, Wrapper, Logo, Shape, PDFQuoteButtons
} from './styles'

interface IQuoteProps {
  hideQuote: () => void
  isVisible: boolean
  service?: string
  languageMatrix?: string
  languageFinal?: string
  numberOfWords?: number
  totalMinutes?: number
  parsedFiles: Array<{
    name: string
  }>
  totalCost?: number
  totalTime?: number
  company?: string
  date: string
}

const PDFQuote: React.FC<IQuoteProps> = ({
  hideQuote,
  isVisible,
  service,
  languageMatrix,
  languageFinal,
  numberOfWords,
  totalMinutes,
  parsedFiles,
  totalCost,
  totalTime,
  company,
  date
}) => {
  const handlePrint = (): void => {
    window.print()
  }

  return (
    <Container isVisible={isVisible}>
      <PDFQuoteButtons>
        <button type="button" onClick={hideQuote}>Voltar</button>
        <button type="button" onClick={handlePrint}>Imprimir ou salvar como pdf</button>
      </PDFQuoteButtons>
      <Wrapper>
        <header>
          <Logo src={konektoLogo} />
          <div>
            <p>Konekto Translations LTDA.</p>
            <p>CNPJ 39.907.000/0001-05</p>
            <p>{`Rio de Janeiro, ${date}`}</p>
            <p>{`para ${company ?? ''}`}</p>
          </div>
        </header>
        <h2>Tipo de serviço</h2>
        <h3>{service}</h3>
        <h2>Línguas</h2>
        <h3>{`de ${languageMatrix ?? ''} para ${languageFinal ?? ''}`}</h3>
        {parsedFiles && (
          <h2>Lista de arquivos</h2>
        )}
        {parsedFiles.map((file) => (
          <h3 key={file.name}>{file.name}</h3>
        ))}
        {numberOfWords && (
          <>
            <h2>Total de palavras</h2>
            <h3>
              {`${formatNumber(numberOfWords)} palavras`}
            </h3>
          </>
        )}
        {totalMinutes && (
          <>
            <h2>Total de minutos</h2>
            <h3>{totalMinutes}</h3>
          </>
        )}
        {totalCost && (
          <>
            <h2>Investimento</h2>
            <h3>{`R$${formatNumber(totalCost)}`}</h3>
          </>
        )}
        <h2>Prazo estimado</h2>
        <h3>{`${totalTime ?? ''} dias úteis*`}</h3>
        <h3>
          *o prazo de entrega dos pedidos feitos após as 16h
          {' '}
          <br />
          {' '}
          são contados a partir do dia seguinte.
        </h3>
        <h4>+55 11 3280 4464 | oi@konekto.me</h4>
        <Shape src={formaVerde} />
      </Wrapper>
    </Container>
  )
}

export default PDFQuote
