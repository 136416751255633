import React, { useEffect, useState } from 'react'
import { FiInfo } from 'react-icons/fi'
import { useQuoteData } from '../../hooks/quoteData'
import { formatNumber, formatCurrency } from '../../helpers/numberFormat'

import Tooltip from '../../components/Tooltip'

import {
  Container, QuoteCalculator, QuotedServices
} from './styles'

const Sidebar: React.FC = () => {
  const { serviceData, serviceTotals, setServiceData } = useQuoteData()
  const [showTitle, setShowTitle] = useState(false)

  const handleRemoveFile = (file: { content: string }, index: number): void => {
    if (serviceData?.files && file.content !== 'loading...') {
      const parsedFiles = serviceData.files
      parsedFiles.splice(index, 1)
      setServiceData({ ...serviceData, files: parsedFiles })
    }
  }

  useEffect(() => {
    if (serviceData?.numberOfWords ?? serviceData?.totalMinutes) {
      setShowTitle(false)
    } else {
      setShowTitle(true)
    }
  }, [serviceData])

  return (
    <Container>
      <h1>Seu projeto</h1>
      {showTitle && (
        <p>As informações do seu projeto vão aparecer aqui.</p>
      )}

      {serviceData && (
        <QuotedServices>

          {serviceData.files?.map((file, index) => (
            <div key={file.filename} className="fileContainer">
              <span>arquivo</span>
              <strong>{file.filename}</strong>
              <button type="button" onClick={() => { handleRemoveFile(file, index) }}>x</button>
            </div>
          ))}

          {serviceData.numberOfWords && (
            <div>
              <span>palavras</span>
              <strong>{formatNumber(serviceData.numberOfWords)}</strong>
            </div>
          )}

          {serviceData.totalMinutes && (
            <div>
              <span>minutos</span>
              <strong>{formatNumber(serviceData.totalMinutes)}</strong>
            </div>
          )}

          {serviceData.languageMatrix && (
            <div>
              <span>lingua fonte</span>
              <strong>{serviceData.languageMatrix}</strong>
            </div>
          )}

          {serviceData.languageFinal && serviceData.selectedService !== 'revision' && (
            <div>
              <span>lingua final</span>
              <strong>{serviceData.languageFinal}</strong>
            </div>
          )}

          {serviceData.service && (
            <div>
              <span>serviço</span>
              <strong>{serviceData.service}</strong>
            </div>
          )}
        </QuotedServices>
      )}

      {serviceTotals?.totalCost && (
        <QuoteCalculator>
          <span>custo total:</span>
          <strong>
            R$
            {serviceTotals.totalCost && formatCurrency(serviceTotals.totalCost)}
          </strong>
        </QuoteCalculator>
      )}

      {serviceTotals?.totalTime && (
        <QuoteCalculator>
          <span>prazo estimado:</span>
          <strong>
            {serviceTotals.totalTime}
            {' '}
            dias úteis
            <Tooltip title="o prazo de entrega dos pedidos feitos após as 16h são contados a partir do dia seguinte." className="deadlineTooltip">
              <FiInfo />
            </Tooltip>
          </strong>
        </QuoteCalculator>
      )}
    </Container>
  )
}

export default Sidebar
