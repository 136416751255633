import {
  FiUser, FiUserPlus, FiZap, FiUserCheck
} from 'react-icons/fi'
import { type IconType } from 'react-icons'

type ITimebasedServices = Record<string, Array<{
  name: string
  ref: string
  value: 'machineTranscription' | 'humanTranscription' | 'captionFile' | 'captionHardcoded' | 'captionBonus'
  description?: string
  benefits?: Array<{
    benefit: string
    icon: IconType
  }>
}>>

type ITextBasedServices = Record<string, Array<{
  name: string
  ref: string
  value: 'machineTranslation' | 'humanTranslation' | 'technicalTranslation' | 'simpleRevision' | 'technicalRevision'
  description?: string
  benefits?: Array<{
    benefit: string
    icon: IconType
  }>
}>>

export const TimebasedServices: ITimebasedServices = {
  transcription: [
    {
      name: 'Transcrição Automática',
      ref: 'b36139e7-57e8-4765-9a36-404ef55a7f9a',
      value: 'machineTranscription',
      description: 'Conversão de áudios em texto, podendo este ser na mesma língua do áudio original ou traduzido. A transcrição é entregue em um documento de texto sem timecodes.',
      benefits: [
        { benefit: 'transcrição automática', icon: FiZap },
        { benefit: 'revisão simples', icon: FiUserCheck }
      ]
    },
    {
      name: 'Transcrição Convencional',
      ref: 'b36139e7-57e8-4765-9a36-404ef55a7f9a',
      value: 'humanTranscription',
      description: 'Conversão de áudios em texto, podendo este ser na mesma língua do áudio original ou traduzido. A transcrição é entregue em um documento de texto sem timecodes.',
      benefits: [
        { benefit: 'profissional nativo', icon: FiUser },
        { benefit: 'revisão simples', icon: FiUserCheck }
      ]
    }
  ],
  subtitling: [
    {
      name: 'Arquivo de Legenda',
      ref: '557938dc-01e2-45b8-961e-fd518eb1ba50',
      value: 'captionFile',
      description: 'As legendas são entregues em arquivo .srt ou .ass que são arquivos padrão de legenda. Estes formatos oferecem mais liberdade para os nossos clientes, permitindo que eles editem a legenda, personalizem a formatação e as insiram ou não no vídeo. Este formato é ideal para clientes que tenham experiência trabalhando com legendas ou tenham parceria com uma produtora de vídeo.',
      benefits: [
        { benefit: 'profissional nativo', icon: FiUser },
        { benefit: 'revisão simples', icon: FiUserCheck }
      ]
    },
    {
      name: 'Legenda Hardcoded',
      ref: '557938dc-01e2-45b8-961e-fd518eb1ba50',
      value: 'captionHardcoded',
      description: 'Ideal para clientes que não tem familiaridade em trabalhar com legendas. Neste caso a legenda é entregue já inserida no vídeo do cliente. Entregamos um arquivo de vídeo com as legendas já gravadas e fixadas no vídeo. As legendas aparecem na parte inferior em letras brancas com sombreado.',
      benefits: [
        { benefit: 'profissional nativo', icon: FiUser },
        { benefit: 'revisão simples', icon: FiUserCheck }
      ]
    },
    {
      name: 'Legenda em outros formatos',
      ref: '557938dc-01e2-45b8-961e-fd518eb1ba50',
      value: 'captionBonus',
      description: 'Formatos específicos, por exemplo .png para facilitar uso com Adobe Premiere ou legendas com regras especiais de posicionamento ou cor.',
      benefits: [
        { benefit: 'profissional nativo', icon: FiUser },
        { benefit: 'revisão simples', icon: FiUserCheck }
      ]
    }
  ]
}

export const TextbasedServices: ITextBasedServices = {
  translationBasic: [
    {
      name: 'Tradução Automática',
      ref: 'cb4fa6ae-31bc-434e-bc8c-e1170b894711',
      value: 'machineTranslation',
      description: 'A tradução é feita usando um software exclusivo e especializado e depois revisado por um dos nossos tradutores nativos.',
      benefits: [
        { benefit: 'tradução automática', icon: FiZap },
        { benefit: 'revisão simples', icon: FiUserCheck }
      ]
    },
    {
      name: 'Tradução Convencional',
      ref: '8baa5a2d-cdea-4252-b1b2-a9abd62fdc28',
      value: 'humanTranslation',
      description: 'Tanto a tradução quanto a revisão são feitas por (no mínimo) dois dos nossos tradutores nativos.',
      benefits: [
        { benefit: 'tradutores nativos', icon: FiUser },
        { benefit: 'revisão simples', icon: FiUserCheck }
      ]
    },
    {
      name: 'Tradução Técnica',
      ref: '6278bdd5-9e5e-4a2f-a307-c7742ea29888',
      value: 'technicalTranslation',
      description: 'A tradução ou a revisão são feitas por tradutores que, além de nativos, são especialistas no setor em questão (jurídico, engenharia, naval etc).',
      benefits: [
        { benefit: 'tradutores nativos', icon: FiUser },
        { benefit: 'revisão especializada', icon: FiUserPlus }
      ]
    }
  ],

  translationAdvanced: [
    {
      name: 'Tradução Convencional',
      ref: '8baa5a2d-cdea-4252-b1b2-a9abd62fdc28',
      value: 'humanTranslation',
      description: 'Tanto a tradução quanto a revisão são feitas por (no mínimo) dois dos nossos tradutores nativos.',
      benefits: [
        { benefit: 'tradutores nativos', icon: FiUser },
        { benefit: 'revisão simples', icon: FiUserCheck }
      ]
    },
    {
      name: 'Tradução Técnica',
      ref: '6278bdd5-9e5e-4a2f-a307-c7742ea29888',
      value: 'technicalTranslation',
      description: 'A tradução ou a revisão são feitas por tradutores que, além de nativos, são especialistas no setor em questão (jurídico, engenharia, naval etc).',
      benefits: [
        { benefit: 'tradutores nativos', icon: FiUser },
        { benefit: 'revisão simples', icon: FiUserPlus }
      ]
    }
  ],

  revision: [
    {
      name: 'Revisão Simples',
      ref: '8d00f5dc-0d08-4590-8198-f0ad922fc323',
      value: 'simpleRevision',
      description: 'O texto é verificado em busca de erros ortográficos, tipográficos e semânticos, visando seu melhor entendimento. Pode ser feito em textos em qualquer língua, traduzidos ou não. Caso o texto tenha sido resultado de uma tradução, é preferível que o texto original também seja enviado para ser usado como insumo e preservar a mensagem original.',
      benefits: [
        { benefit: 'revisores nativos', icon: FiUser }
      ]
    },
    {
      name: 'Revisão Técnica',
      ref: '376ada69-dc86-42cf-87a8-4e76303aaf7c',
      value: 'technicalRevision',
      description: 'O texto é verificado em busca de erros ortográficos, tipográficos e semânticos, visando seu melhor entendimento. Pode ser feito em textos em qualquer língua, traduzidos ou não. Caso o texto tenha sido resultado de uma tradução, é preferível que o texto original também seja enviado para ser usado como insumo e preservar a mensagem original.',
      benefits: [
        { benefit: 'revisores nativos especializados', icon: FiUserPlus }
      ]
    }
  ]
}
