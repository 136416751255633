import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 7px 0px;
`

export const Navbar = styled.nav`
  width: 90%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  z-index: 2;

  > a{
    display: block;
  }

  img{
    max-width: 120px;
    margin: 0px;
  }

  div{
    a{
      padding: 5px 20px;
      color: #000;
      text-decoration: none;
      font-size: 16px;
      line-height: 1.2em;
      font-weight: 300;
      letter-spacing: .5px;
    }

    a.phone{
      margin-left: 10px;
      padding: 7px 10px;
      border-radius: 5px;
      background-color: #b776b2;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
    }
    
    button{
      margin-left: 10px;
      padding: 7px 10px;
      border-radius: 5px;
      background-color: #b776b2;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      border: none;
      cursor: pointer;
    }
  }

  @media(max-width: 550px){
    position: relative;
    padding: 0 10px;
    button{
      display: none;
    }
  }
`

export const Cover = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: center;

  h1{
    margin-bottom: 10px;
    
    font-size: 50px;
    font-weight: 640;
    font-variation-settings:
      "wdth" 55,
      "GRAD" -50;
  }

  p{
    margin: 0;
    max-width: 680px;
    font-size: 18px;
    line-height: 1.4em;
  }

  @media(max-width: 550px){
    height: auto;
    background-size: auto 95%,auto 95%;
    padding-top: 20px;
    h1{
      font-size: 30px;
      margin: 0 20px;
    }

    p{
      max-width: 80%;
      font-size: 15px;
      margin-top: 10px;
    }
  }
`
