import React, { useState, useEffect } from 'react'

import { Container, BarsContainer, ProgressBar } from './styles'

interface ILoadingMultiple {
  breakpoints: number
  currentBreakpoint: number
  displayText: string
}

interface IProgressBars {
  completed: boolean
  loading: boolean
  key: string
}

const LoadingMultiple: React.FC<ILoadingMultiple> = ({
  breakpoints, currentBreakpoint, displayText
}) => {
  const [progressBars, setProgressBars] = useState<IProgressBars[]>([])

  useEffect(() => {
    const bars = []
    for (let i = 1; i <= breakpoints; i += 1) {
      if (i < currentBreakpoint) {
        bars.push({ completed: true, loading: false, key: `${i}key` })
      } else if (i === currentBreakpoint) {
        bars.push({ completed: false, loading: true, key: `${i}key` })
      } else {
        bars.push({ completed: false, loading: false, key: `${i}key` })
      }
    }
    setProgressBars(bars)
  }, [currentBreakpoint])

  return (
    <Container>
      <h2>{displayText}</h2>
      <BarsContainer>
        {progressBars.map((bar) => (
          <ProgressBar key={bar.key} isComplete={bar.completed} isLoading={bar.loading}><span /></ProgressBar>
        ))}
      </BarsContainer>
    </Container>
  )
}

export default LoadingMultiple
