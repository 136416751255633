import styled from 'styled-components'
import { colors } from 'styles/GlobalStyles'

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.pink};
  background-image: linear-gradient(140deg,#a5349c,#b776b2);
  color: #fff;
  padding: 40px;

  box-shadow: 0 6px 30px -12px #000;

  p{
    margin: 0;
  }

  @media(max-width: 550px){
    padding: 20px;
  }
`

export const QuotedServices = styled.div`
  div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
  }

  div + div{
    border-top: 1px solid #fff;
  }

  div:first-child{
    margin-top: 20px;
  }

  strong{
    text-align: right;
  }

  button{
    border-radius: 30px;
    background-color: red;
    color: #fff;
    border: none;
    outline: none;
    padding: 3px 10px 5px;
    font-size: 17px;
    position: absolute;
    right: -27px;
    top: 7px;
    font-weight: 700;
    cursor: pointer;

    display:none;
    opacity: 0;
  }

  div.fileContainer:hover{
    button{
      display: block;
      opacity: 100;
    }
  }

  @media(max-width: 550px){
    span{
      font-size: 13px;
    }

    strong{
      margin-left: 10px;
      font-size: 13px;
      font-weight: 500;
    }

    button{
      display: block;
      opacity: 100;
      position: relative;
      font-size: 14px;
      font-weight: 400;
      right: 0;
      top: 0;
      margin-left: 5px;
      padding: 2px 7px 3px;
    }
  }
`

export const QuoteCalculator = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #fff;

  .deadlineTooltip{
    display: inline-block;
    margin: 0 0 0 5px;
     svg{
      vertical-align: top;
     }
  }
`
