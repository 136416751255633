import styled, { css } from 'styled-components'
import { colors } from 'styles/GlobalStyles'

export const Container = styled.div`
  width: 48%;

  @media(max-width: 550px){
    width: 100%;
    margin-bottom: 10px;

    button{
      font-size: 13px;
      font-weight: 400;
    }
  }
`

interface IPopupProps {
  isVisible: boolean
}

export const PopUp = styled.div<IPopupProps>`
  display: none;
  ${(props) => props.isVisible && css`
    display: flex;
  `}

  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: #489ec7e6;

  > div{
    width: 90%;
    max-width: 500px;
  }
`

export const FormLike = styled.div`
  display: flex;
  flex-direction: column;

  input{
    border: none;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 17px;
    outline: none;
    color: #247195;
  }
`

export const LineButton = styled.button`
  width: 100%;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  padding: 17px 4px 16px;
  font-weight: 700;
  font-size: 17px;
  letter-spacing: 1px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all .3s;

  &:hover{
    background: #ffffff14;
  }
  
  > div {
    margin-left: 6px;
    padding-top: 3px;

    > span {
      background: ${colors.pink};
      z-index: 22;

      &:before{
        border-color: ${colors.pink} transparent; 
      }
    }
  }

  @media(max-width: 550px){
    border-radius: 5px;
    > div {
      display: none;
    }
  }
`

export const ClosePopup = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
  border: none;
  padding: 20px;
  background: transparent;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
`
