import styled from 'styled-components'

export const CupomContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  > div{
    width: 32%;
  }

  div + div{
    margin-left: 1%;
  }

  div:last-child{
    input{
      background-color: #0861bd;
      color: #fff;
      transition: all .3s;
      cursor: pointer;
      &:hover{
        background-color: #0551a0;
      }
    }
  }
`
