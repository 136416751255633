import styled, { css } from 'styled-components'
import { ProductCardStyles } from '../../styles/AppStyles'

interface IProductCardProps {
  isSelected?: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  width: 100%;
  margin: 20px 0 40px;

  @media(max-width: 550px){
    flex-direction: column;
  }
`

export const ProductCard = styled.div<IProductCardProps>`
  ${ProductCardStyles}

  ${(props) => props.isSelected && css`
    background: #dff1fc;
  `}
`

export const PriceCut = styled.aside`
  position: relative;
`

export const DiscountTag = styled.aside`
  background-color: #fcef00;
  position: absolute;
  top: -10px;
  right: -35px;
  color: #000;
  border-radius: 50%;
  width: 37px;
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 11px;
  justify-content: center;
  font-weight: 900;
  transform: rotate(18deg);
  letter-spacing: 0px;
`
