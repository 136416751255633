/* eslint-disable no-unused-vars */
import React, {
  createContext, useContext, useMemo, useState
} from 'react'
import type { IProviderProps } from './provider.interface'

interface ICouponContext {
  couponData: ICouponData | undefined
  setCouponData: (data: ICouponData) => void
}

export interface ICouponData {
  code?: string
  percent?: number
}

const CouponContext = createContext<ICouponContext>({
  couponData: undefined,
  setCouponData: (data: ICouponData) => { }
})

const CouponProvider: React.FC<IProviderProps> = ({ children }) => {
  const [couponData, setCouponData] = useState<ICouponData>()

  const MemoCouponData = useMemo(() => ({ couponData, setCouponData }), [couponData])

  return (
    <CouponContext.Provider value={MemoCouponData}>
      {children}
    </CouponContext.Provider>
  )
}

function useCoupon (): ICouponContext {
  const context = useContext(CouponContext)

  if (!context) {
    throw new Error('Context must be used inside CouponProvider')
  }

  return context
}

export { CouponProvider, useCoupon }
