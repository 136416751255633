import React, { useRef, useState } from 'react'
import { Form } from '@unform/web'
import { type FormHandles } from '@unform/core'
import * as Yup from 'yup'

import Input from 'components/Input'
// import FileInput from 'components/Input/FileInput'
import Textarea from 'components/Textarea'
import apiEndpoints from 'helpers/api'
import { useForm } from 'hooks/contactForm'
import { useToast } from 'hooks/toast'
import { useQuoteData } from 'hooks/quoteData'
import getValidationErrors from 'utils/getValidationErrors'

import { Container, Row, CloseButton } from './styles'

interface IFormData {
  name: string
  company: string
  email: string
  phone: string
  message: string
  formAttachments: string
}

const OverlayForm: React.FC = () => {
  const { serviceData } = useQuoteData()
  const { addToast } = useToast()
  const formRef = useRef<FormHandles>(null)
  const { toggleVisibility, formVisibility } = useForm()
  const [formData, setFormData] = useState<IFormData>()

  const handleUpdate = async (): Promise<void> => {
    if (formRef.current === null) return
    formRef.current.setErrors({})

    try {
      const formValues = (formRef.current.getData() as IFormData)

      const schema = Yup.object().shape({
        name: Yup.string().required('este campo é obrigatório'),
        phone: Yup.string().required('este campo é obrigatório').matches(/(\+\d{2}\s?)?(\(?\d{2}\)?\s?)?(\d{4,5}-?\s?\d{4})|(\+\d{2}\s?)?(\(?\d{2}\)?\s?)?(\d{3}-?\s?\d{3}-?\s?\d{3})/, 'use apenas números'),
        email: Yup.string().email('insira um email válido').required('este campo é obrigatório'),
        company: Yup.string(),
        message: Yup.string()
      })

      await schema.validate(formValues, {
        abortEarly: false
      })

      setFormData(formValues)
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors)
      }
    }
  }

  const handleSubmit = async (): Promise<void> => {
    toggleVisibility()
    addToast({
      title: 'Enviando...',
      message: 'Estamos enviando sua mensagem',
      type: 'info'
    })
    try {
      const payload = {
        ...formData,
        attachments: serviceData?.files
      }
      await apiEndpoints.contactEmail.post('/', payload)
      addToast({
        title: 'Enviado',
        message: 'Recebemos seu email, retornamos em breve!',
        type: 'success'
      })
    } catch {
      addToast({
        title: 'Ocorreu um erro',
        message: 'tente novamente',
        type: 'error'
      })
    }
  }

  return (
    <Container isVisible={formVisibility}>
      <h3>Contato</h3>
      <CloseButton onClick={() => { toggleVisibility() }}>
        <span className="material-symbols-outlined">close</span>
      </CloseButton>
      <Form ref={formRef} onSubmit={() => { }} placeholder={''} onPointerEnterCapture={''} onPointerLeaveCapture={''}>
        <Input type="hidden" name="formName" value="Contato pelo site"></Input>
        <Row>
          <Input name="email" placeholder="E-Mail" type="email" onChange={handleUpdate} />
          <Input name="name" placeholder="Nome" onChange={handleUpdate} />
        </Row>
        <Row>
          <Input name="company" placeholder="Nome da Empresa" onChange={handleUpdate} />
          <Input name="phone" placeholder="Telefone" onChange={handleUpdate} />
        </Row>
        <Textarea name="message" placeholder="sua mensagem" onChange={handleUpdate} ></Textarea>
        <Row>
          {/* <FileInput />
          {serviceData?.files && <p>{serviceData?.files.length} arquivo(s)</p>} */}
          <button onClick={handleSubmit}>enviar</button>
        </Row>
      </Form>
    </Container>
  )
}

export default OverlayForm
