import styled, { css } from 'styled-components'

interface ContainerProps {
  maxWidth?: number
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  ${(props) => props.maxWidth && css`
    max-width: ${props.maxWidth}%;
  `}

  input, select{
    width: 100%;
    margin-bottom: 17px;
    padding: 9px 25px;
    border: 1px #000;
    border-radius: 20px;
    color: #1c5ab6;
    font-size: 16px;
    line-height: 1.4em;

    &:focus{
      outline: none;
    }
  }

  aside {
    color: #fff;
    font-size: 14px;
    padding-left: 15px;
    margin-bottom: 4px;
  }

  @media(max-width: 550px){
    max-width: 100%;
  }
`
