import styled from 'styled-components'
import { colors } from 'styles/GlobalStyles'

export const Container = styled.section`
  width: 100%;
  margin-top: 50px;
  padding: 60px 20px;
  background-image: linear-gradient(104deg,${colors.pink},${colors.blue});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3{
    color: #fff;
    text-align: center;
    max-width: 550px;
    font-size: 25px;
    line-height: 1.2em;

    font-family: "Roboto Flex", sans-serif;
    font-optical-sizing: auto;
    font-weight: 350;
    font-style: normal;
    font-variation-settings:
      "slnt" 0,
      "wdth" 79,
      "GRAD" -24,
      "XOPQ" 96,
      "XTRA" 468,
      "YOPQ" 79,
      "YTAS" 854,
      "YTDE" -305,
      "YTFI" 738,
      "YTLC" 514,
      "YTUC" 712;
  }

  em{
    font-style: unset;
    font-weight: 750;
  }

  button{
    font-size: 14px;
    padding: 15px 18px 13px;
    text-transform: uppercase;
    border-radius: 50px;
    font-variation-settings:
      "wdth" 150;
    letter-spacing: 1px;
    border: none;

    transition: all .3s;
    cursor: pointer;

    &:hover{
      background-color: #000;
      color: #fff;
    }
  }

  button + button { margin: 0 0 0 20px; }

  @media(max-width: 530px){
    h3{ font-size: 17px; }
    div{ flex-direction: column; }
    button{ font-size: 10px; }
    button + button{ margin: 10px 0 0 0; }
  }
`
