import React from 'react'
import { Container, BlockContato } from './styles'

const Footer: React.FC = () => (
  <Container>
    <h2>
      estamos sempre à disposição para
      <br />
      responder suas perguntas!
    </h2>

    <div>
      <BlockContato>
        ligue agora
        <strong>+55 11 3280 4464</strong>
      </BlockContato>

      <BlockContato>
        nosso email
        <strong>oi@konekto.me</strong>
      </BlockContato>

      {/* <BlockContato>
        veja as
        <a href="https://www.konekto.me/perguntas-frequentes" target="_black" rel="noreferrer">Perguntas Frequentes</a>
      </BlockContato> */}

      {/* <BlockContato>
        leia nossos
        <a href="https://www.konekto.me/termos-e-garantias" target="_black" rel="noreferrer">Termos e Garantias</a>
      </BlockContato> */}
    </div>
  </Container>
)

export default Footer
