import React from 'react'
import { useTransition } from 'react-spring'
import { Container } from './styles'
import Toast from './Toast'

interface ToastContainerProps {
  messages: ToastMessage[]
}

interface ToastMessage {
  id: string
  type?: 'info' | 'success' | 'error'
  title: string
  message?: string
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  const animatedMessages = useTransition(messages, {
    from: { bottom: '-300px' },
    enter: { bottom: '0px' },
    leave: { bottom: '-450px' }
  })

  return (
    <Container>
      {animatedMessages((styles, message) => (
        <Toast key={message.id} message={message} style={styles} />
      ))}
    </Container>
  )
}

export default ToastContainer
