import styled, { css } from 'styled-components'
import { animated } from 'react-spring'
import { colors } from 'styles/GlobalStyles'

interface ContainerProps {
  type?: 'info' | 'success' | 'error'
  hasdescription: number
}

const toastTypeVariations = {
  info: css`
    background-color: ${colors.pink};
    background-image: linear-gradient(140deg,#a5349c,#b776b2);
    color: #fff;
  `,
  success: css`
    background: #e6fffa;
    color: #2e656a;
  `,
  error: css`
    background: #fddede;
    color: #c53030;
  `
}

export const Container = styled(animated.div) <ContainerProps>`
  position: relative;
  padding: 40px;
  box-shadow: 0px 0px 20px -10px rgba(0,0,0,1);
  width: 500px;
  border-radius: 5px;

  display: flex;

  & + div{
    margin-top: 8px;
  }

  ${(props) => toastTypeVariations[props.type ?? 'info']}

  > svg{
    margin: 4px 12px 0 0;
  }

  div{
    flex: 1;
    p{
      margin-top: 4px;
      font-size: 22px;
      opacity: 0.8;
      line-height: 1.3em;
    }
  }

  button{
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${(props) => !props.hasdescription && css`
    align-items: center;
    svg{margin-top: 0;}
  `}

  @media(max-width: 550px){
    width: 90%;
    padding: 20px;

    div{
      margin-right: 25px;
    }

    button{
      right: 5px;
      top: 15px;
    }
  }
`
