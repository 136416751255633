import React from 'react'
import { QuoteProvider } from './quoteData'
import { ToastProvider } from './toast'
import { CouponProvider } from './coupon'
import { ContactFormProvider } from './contactForm'
import type { IProviderProps } from './provider.interface'

const AppProvider: React.FC<IProviderProps> = ({ children }) => (
  <ToastProvider>
    <QuoteProvider>
      <CouponProvider>
        <ContactFormProvider>
          {children}
        </ContactFormProvider>
      </CouponProvider>
    </QuoteProvider>
  </ToastProvider>
)

export default AppProvider
