import React from 'react'

import PortaLogo from 'images/logo-porta-desktop.svg'
import AzulLogo from 'images/LogoAzul.png'

import { Wrapper, Container, CaseContainer, Heading } from './styles'

const Case: React.FC = () => {
  return (
    <Wrapper>
      <Heading>Parcerias de sucesso</Heading>
      <Container>
        <CaseContainer>
          <figure>
            <img src={PortaLogo} alt="Porta dos Fundos" />
          </figure>
          <h3><em>Legendagem e Transcrição</em> para inglês de todos os conteúdos publicados nos canais online</h3>
          <p>O Porta dos Fundos é um coletivo de humor brasileiro, conhecido por seus vídeos satíricos e esquetes que abordam temas sociais e políticos de maneira irreverente. Fundado em 2012, o grupo ganhou notoriedade no cenário online, conquistando uma vasta audiência por meio de suas produções humorísticas provocativas. A abordagem ousada e a qualidade criativa de suas produções contribuíram para sua relevância no humor brasileiro contemporâneo.</p>
        </CaseContainer>
        <CaseContainer>
          <img src={AzulLogo} alt="Azul linhas aéreas" />
          <h3><em>Tradução</em> para inglês, francês e espanhol de toda a comunicação de marketing, comunicação visual, manuais informativos, contratos e diversas demandas pontuais que uma linha área internacional demanda.</h3>
          <p>A Azul Linhas Aéreas, fundada em 2008, é uma destacada companhia aérea brasileira conhecida por sua abordagem inovadora e eficiência operacional. Sua extensa malha conecta mais de 100 destinos, oferecendo uma experiência de viagem completa. Além do transporte de passageiros, a empresa atua na logística de carga e destaca-se por iniciativas sustentáveis.</p>
        </CaseContainer>
      </Container>
    </Wrapper>
  )
}

export default Case
