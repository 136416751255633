import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media(max-width: 550px){
    flex-direction:column;
  }
`

export const Button = styled.button`
  background: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  color: #497ecf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;

  padding: 20px;
  width: 24%;

  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.4em;

  transform: translateY(0px);
  transition: all .2s;

  span{
    font-size: 12px;
    font-weight: bold;
    line-height: 1.2em;
    margin-top: 2px;
    letter-spacing: 0px;
  }

  &:hover{
    box-shadow: 0px 6px 20px -10px #0d0053;
    transform: translateY(-2px);
  }

  @media(max-width: 550px){
    width: 100%;
    margin-bottom: 10px;
  }
`
