/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react'
import { FiInfo } from 'react-icons/fi'

import apiEndpoints from 'helpers/api'
import { useQuoteData } from 'hooks/quoteData'
import { useToast } from 'hooks/toast'
import { formatCurrency } from 'helpers/numberFormat'
import PDFQuote from 'components/PDFQuote'

import {
  Container, DownloadPdf, ThankYou, SubmitQuote, LoadingContainer
} from './styles'
import LoadingMultiple from 'components/LoadingMultiple'

interface IQuoteFinishProps {
  isActive: boolean
}

const QuoteFinish: React.FC<IQuoteFinishProps> = ({
  isActive
}) => {
  const {
    serviceData, personalData, serviceTotals
  } = useQuoteData()
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)
  const [showThankYou, setShowThankYou] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [progressState, setProgressState] = useState({ breakpoint: 0, displayText: 'Processando seu pedido...' })
  const { addToast } = useToast()

  const fullDate = useMemo(() => {
    const date = new Date()
    const month = date.toLocaleString('pt-BR', { month: 'long' })
    return `${date.getDate()} de ${month} de ${date.getFullYear()}`
  }, [])

  const parsedFiles = useMemo(() => {
    const parsedArray: any = []
    if (serviceData?.files) {
      for (const file of serviceData.files) {
        parsedArray.push({ name: file.filename })
      }
    }
    return parsedArray
  }, [serviceData])

  const handleSendEmail = async (): Promise<void> => {
    if (isActive && serviceData && personalData && serviceTotals?.totalCost) {
      setShowLoading(true)
      setProgressState({ breakpoint: 1, displayText: 'Processando seus dados...' })
      try {
        await apiEndpoints.newOrder.post('/', {
          name: personalData.name,
          email: personalData.email,
          service: serviceData.service,
          company: personalData.company,
          deadline: serviceTotals.totalTime,
          cost: formatCurrency(serviceTotals.totalCost),
          numberOfWords: serviceData.numberOfWords,
          totalMinutes: serviceData.totalMinutes,
          languageFrom: serviceData.languageMatrix,
          languageTo: serviceData.languageFinal,
          cpfcnpj: personalData.cnpjcpf,
          cep: personalData.cep,
          phone: personalData.phone,
          message: personalData.message,
          attachments: serviceData.files
        })

        setProgressState({ breakpoint: 2, displayText: 'Tudo certo!' })

        setTimeout(() => {
          setShowLoading(false)
          setShowThankYou(true)
        }, 800)
      } catch {
        setShowLoading(false)
        addToast({
          title: 'Ocorreu um erro durante o envio. Aguarde um momento e tente novamente.',
          type: 'error'
        })
      }
    } else {
      addToast({
        title: 'Não foi possível enviar seu orçamento. Preencha todos os campos e tente novamente.',
        type: 'error'
      })
    }
  }

  const handleShowQuote = (): void => {
    if (isActive) {
      setIsQuoteVisible(true)
    }
  }

  const handleCloseThankYou = (): void => {
    setShowThankYou(false)
  }

  return (
    <Container>
      <SubmitQuote type="button" onClick={handleSendEmail} isActive={isActive}>
        Receber Orçamento
        <FiInfo size={20} />
      </SubmitQuote>

      <DownloadPdf type="button" isActive={isActive} onClick={handleShowQuote}>Visualizar e imprimir orçamento</DownloadPdf>

      <PDFQuote
        hideQuote={() => { setIsQuoteVisible(false) }}
        isVisible={isQuoteVisible}
        numberOfWords={serviceData?.numberOfWords}
        totalMinutes={serviceData?.totalMinutes}
        service={serviceData?.service}
        languageMatrix={serviceData?.languageMatrix}
        languageFinal={serviceData?.languageFinal}
        parsedFiles={parsedFiles}
        totalCost={serviceTotals?.totalCost}
        totalTime={serviceTotals?.totalTime}
        company={personalData?.company}
        date={fullDate}
      />

      <LoadingContainer isVisible={showLoading}>
        <LoadingMultiple breakpoints={1} currentBreakpoint={progressState.breakpoint} displayText={progressState.displayText} />
      </LoadingContainer>

      <ThankYou isVisible={showThankYou}>
        <div>
          <button type="button" onClick={handleCloseThankYou}>voltar</button>
          <h1>Agradecemos seu pedido!</h1>
          <p>enviamos por email os detalhes deste orçamento, em breve retornaremos com as opções de pagamento.</p>
          <h2>oi@konekto.me | +55 11 3280 4464</h2>
        </div>
      </ThankYou>

    </Container>
  )
}

export default QuoteFinish
