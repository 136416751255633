import React, { type TextareaHTMLAttributes, useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { FiAlertCircle } from 'react-icons/fi'

import Tooltip from '../Tooltip'
import { Container, Error } from './styles'

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string
  placeholder?: string
  label?: string
  tooltip?: string
  maxWidth?: number
}

const Textarea: React.FC<TextareaProps> = ({
  name, placeholder, label, tooltip, maxWidth, ...rest
}) => {
  const textareaRef = useRef(null)
  const {
    fieldName, defaultValue, registerField, error
  } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])
  return (
    <Container isErrored={!!error} maxWidth={maxWidth}>
      {label && (
        <aside>
          {label}
          {tooltip && (
            <Tooltip title={tooltip}><FiAlertCircle size={14} /></Tooltip>
          )}
        </aside>
      )}
      <textarea
        placeholder={placeholder}
        ref={textareaRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53333" size={20} />
        </Error>
      )}
    </Container>
  )
}

export default Textarea
