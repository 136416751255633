import React, { type SelectHTMLAttributes, useEffect, useRef } from 'react'
import { useField } from '@unform/core'

import { Container } from './styles'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  label?: string
  maxWidth?: number
}

const Select: React.FC<SelectProps> = ({
  name, children, label, maxWidth, ...rest
}) => {
  const inputRef = useRef(null)
  const {
    fieldName, defaultValue, registerField, error
  } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])
  return (
    <Container maxWidth={maxWidth}>
      {label && (
        <aside>{label}</aside>
      )}
      <select
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      >
        {children}
      </select>
      {error && (
        <span>{error}</span>
      )}
    </Container>
  )
}

export default Select
