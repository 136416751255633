import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: auto;
  padding-bottom: 100px;
  z-index:5;

  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;

  @media(max-width: 550px){
    width: 100%;
    left: 5%;
    transform: translateX(0);
    font-size: 20px;
  }
`
