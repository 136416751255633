const allLanguages = [
  { name: 'Espanhol (ES)', group: 1 },
  { name: 'Espanhol (LATAM)', group: 1 },
  { name: 'Inglês US', group: 1 },
  { name: 'Inglês UK', group: 1 },
  { name: 'Português BR', group: 1 },
  { name: 'Português PT', group: 1 },
  { name: 'Francês', group: 2 },
  { name: 'Franco-canadense', group: 2 },
  { name: 'Italiano', group: 2 },
  { name: 'Alemão', group: 2 },
  { name: 'Árabe', group: 3 },
  { name: 'Chinês (Mandarim Simplificado)', group: 3 },
  { name: 'Dinamarquês', group: 3 },
  { name: 'Finlandês', group: 3 },
  { name: 'Hebraico', group: 3 },
  { name: 'Holandês', group: 3 },
  { name: 'Islandês', group: 3 },
  { name: 'Japonês', group: 3 },
  { name: 'Norueguês', group: 3 },
  { name: 'Russo', group: 3 },
  { name: 'Sueco', group: 3 },
  { name: 'Tcheco', group: 3 },
  { name: 'Turco', group: 3 },
  { name: 'Polonês', group: 3 },
  { name: 'Grego', group: 3 },
  { name: 'Africâner', group: 4 },
  { name: 'Akan', group: 4 },
  { name: 'Albanês', group: 4 },
  { name: 'Amárico', group: 4 },
  { name: 'Armênio', group: 4 },
  { name: 'Assamês', group: 4 },
  { name: 'Assírio', group: 4 },
  { name: 'Azeri', group: 4 },
  { name: 'Bambara', group: 4 },
  { name: 'Basco', group: 4 },
  { name: 'Bashkir', group: 4 },
  { name: 'Bengalês', group: 4 },
  { name: 'Bielo - russo', group: 4 },
  { name: 'Birmanês', group: 4 },
  { name: 'Bósnio', group: 4 },
  { name: 'Bravanês', group: 4 },
  { name: 'Búlgaro', group: 4 },
  { name: 'Butanês', group: 4 },
  { name: 'Cambojano', group: 4 },
  { name: 'Canarês', group: 4 },
  { name: 'Canjobal', group: 4 },
  { name: 'Cantonês', group: 4 },
  { name: 'Catalão', group: 4 },
  { name: 'Caxemira', group: 4 },
  { name: 'Cazaque', group: 4 },
  { name: 'Cebuano', group: 4 },
  { name: 'Chaldean', group: 4 },
  { name: 'Chamorro', group: 4 },
  { name: 'Chaozhou', group: 4 },
  { name: 'Chavacano', group: 4 },
  { name: 'Chinês (Mandarim Tradicional)', group: 4 },
  { name: 'Chona', group: 4 },
  { name: 'Chuquês', group: 4 },
  { name: 'Cingalês', group: 4 },
  { name: 'Coreano', group: 4 },
  { name: 'Cree', group: 4 },
  { name: 'Croata', group: 4 },
  { name: 'Curdo', group: 4 },
  { name: 'Curmânji', group: 4 },
  { name: 'Dacota', group: 4 },
  { name: 'Dari', group: 4 },
  { name: 'Dinka', group: 4 },
  { name: 'Dioula', group: 4 },
  { name: 'Eslovaco', group: 4 },
  { name: 'Esloveno', group: 4 },
  { name: 'Estoniano', group: 4 },
  { name: 'Ewe', group: 4 },
  { name: 'Fanti', group: 4 },
  { name: 'Faroês', group: 4 },
  { name: 'Farsi', group: 4 },
  { name: 'Flamengo', group: 4 },
  { name: 'Frísio', group: 4 },
  { name: 'Fujian', group: 4 },
  { name: 'Fujianês', group: 4 },
  { name: 'Fula', group: 4 },
  { name: 'Fulani', group: 4 },
  { name: 'Fuzhou', group: 4 },
  { name: 'Gaélico', group: 4 },
  { name: 'Galego', group: 4 },
  { name: 'Galês', group: 4 },
  { name: 'Ganda', group: 4 },
  { name: 'Georgiano', group: 4 },
  { name: 'Gorani', group: 4 },
  { name: 'Guanxi', group: 4 },
  { name: 'Gujarati', group: 4 },
  { name: 'Hacá', group: 4 },
  { name: 'Haitiano', group: 4 },
  { name: 'Hassaniya', group: 4 },
  { name: 'Hauçá', group: 4 },
  { name: 'Hiligaynon', group: 4 },
  { name: 'Hindi', group: 4 },
  { name: 'Hindi fijiano', group: 4 },
  { name: 'Hmong', group: 4 },
  { name: 'Húngaro', group: 4 },
  { name: 'Ibanag', group: 4 },
  { name: 'Igbo', group: 4 },
  { name: 'Iídiche', group: 4 },
  { name: 'Ilocano', group: 4 },
  { name: 'Ilonggo', group: 4 },
  { name: 'Indiano', group: 4 },
  { name: 'Indonésio', group: 4 },
  { name: 'Inuktitut', group: 4 },
  { name: 'Iorubá', group: 4 },
  { name: 'Irlandês', group: 4 },
  { name: 'Jakartanese', group: 4 },
  { name: 'Javanês', group: 4 },
  { name: 'Karen', group: 4 },
  { name: 'Khalkha', group: 4 },
  { name: 'Khmer', group: 4 },
  { name: 'Kikuyu', group: 4 },
  { name: 'Kirundi', group: 4 },
  { name: 'Kosovo', group: 4 },
  { name: 'Kotokoli', group: 4 },
  { name: 'Krio', group: 4 },
  { name: 'Lakota', group: 4 },
  { name: 'Laociano', group: 4 },
  { name: 'Latim', group: 4 },
  { name: 'Letão', group: 4 },
  { name: 'Lingala', group: 4 },
  { name: 'Língua de sinais americana', group: 4 },
  { name: 'Lituano', group: 4 },
  { name: 'Luganda', group: 4 },
  { name: 'Luo', group: 4 },
  { name: 'Lusoga', group: 4 },
  { name: 'Luxemburguês', group: 4 },
  { name: 'Maay', group: 4 },
  { name: 'Macedônio', group: 4 },
  { name: 'Malaiala', group: 4 },
  { name: 'Malaio', group: 4 },
  { name: 'Maldivense', group: 4 },
  { name: 'Malgaxe', group: 4 },
  { name: 'Maltês', group: 4 },
  { name: 'Mandarim Tradicional', group: 4 },
  { name: 'Mandinga', group: 4 },
  { name: 'Mandinka', group: 4 },
  { name: 'Maori', group: 4 },
  { name: 'Marata', group: 4 },
  { name: 'Marshalês', group: 4 },
  { name: 'Mien', group: 4 },
  { name: 'Mirpuri', group: 4 },
  { name: 'Mixteco', group: 4 },
  { name: 'Moldovan', group: 4 },
  { name: 'Mongol', group: 4 },
  { name: 'Napolitano', group: 4 },
  { name: 'Navajo', group: 4 },
  { name: 'Nepali', group: 4 },
  { name: 'Nianja', group: 4 },
  { name: 'Nuer', group: 4 },
  { name: 'Ojíbua', group: 4 },
  { name: 'Oriá', group: 4 },
  { name: 'Oromo', group: 4 },
  { name: 'Osseta', group: 4 },
  { name: 'Pachto', group: 4 },
  { name: 'Pahari', group: 4 },
  { name: 'Pampangan', group: 4 },
  { name: 'Patoá', group: 4 },
  { name: 'Pothwari', group: 4 },
  { name: 'Pulaar', group: 4 },
  { name: 'Punjabi', group: 4 },
  { name: 'Putian', group: 4 },
  { name: 'Quechua', group: 4 },
  { name: 'Quiniaruanda', group: 4 },
  { name: 'Quirguiz', group: 4 },
  { name: 'Romanche', group: 4 },
  { name: 'Romani', group: 4 },
  { name: 'Romeno', group: 4 },
  { name: 'Rundi', group: 4 },
  { name: 'Samoano', group: 4 },
  { name: 'Sango', group: 4 },
  { name: 'Sânscrito', group: 4 },
  { name: 'Sérvio', group: 4 },
  { name: 'Sichuan', group: 4 },
  { name: 'Siciliano', group: 4 },
  { name: 'Sindi', group: 4 },
  { name: 'Somali', group: 4 },
  { name: 'Soninquê', group: 4 },
  { name: 'Sorâni', group: 4 },
  { name: 'Soto do sul', group: 4 },
  { name: 'Suaíle', group: 4 },
  { name: 'Suázi / suazilandês', group: 4 },
  { name: 'Sundanês', group: 4 },
  { name: 'Susu', group: 4 },
  { name: 'Sylheti', group: 4 },
  { name: 'Tadjique', group: 4 },
  { name: 'Tagalog', group: 4 },
  { name: 'Tailandês', group: 4 },
  { name: 'Taiwanês', group: 4 },
  { name: 'Tâmil', group: 4 },
  { name: 'Telugu', group: 4 },
  { name: 'Tibetano', group: 4 },
  { name: 'Tigrínia', group: 4 },
  { name: 'Tonga', group: 4 },
  { name: 'Tshiluba', group: 4 },
  { name: 'Tsonga', group: 4 },
  { name: 'Tswana', group: 4 },
  { name: 'Turcomeno', group: 4 },
  { name: 'Ucraniano', group: 4 },
  { name: 'Uigur', group: 4 },
  { name: 'Uolofe', group: 4 },
  { name: 'Urdu', group: 4 },
  { name: 'Uzbeque', group: 4 },
  { name: 'Venda', group: 4 },
  { name: 'Vietnamita', group: 4 },
  { name: 'Visayan', group: 4 },
  { name: 'Xangainês', group: 4 },
  { name: 'Xhosa', group: 4 },
  { name: 'Yao', group: 4 },
  { name: 'Yupik', group: 4 },
  { name: 'Zulu', group: 4 }
]

export default allLanguages
