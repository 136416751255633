import React, { type ReactNode, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'

import apiEndpoints from 'helpers/api'
import { useToast } from 'hooks/toast'
import { useQuoteData } from 'hooks/quoteData'

import LinkZone from './LinkZone'
import {
  DropContainer, UploadMessage, Container, UploadCounter
} from './styles'

const Upload: React.FC = () => {
  const { serviceData, setServiceData } = useQuoteData()
  const [fileCounter, setFileCounter] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const { addToast } = useToast()

  function renderDragMessage (
    isDragActive: boolean,
    isDragRejest: boolean
  ): ReactNode {
    if (!isDragActive) {
      return (
        <UploadMessage type="default">Adicionar arquivos (até 10Mb)</UploadMessage>
      )
    }

    if (isDragRejest) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>
    }

    return <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>
  }

  const handleOnUpload = async (files: File[]): Promise<void> => {
    let parsedFiles: any[] = []
    setIsUploading(true)
    addToast({
      title: 'Estamos fazendo o upload do seu arquivo...',
      type: 'info'
    })
    if (serviceData?.files) {
      parsedFiles = serviceData.files
    }

    for (const file of files) {
      const formData = new FormData()
      formData.append('attachment', file)

      parsedFiles.push({
        filename: 'carregando novo arquivo...',
        content: 'loading...'
      })
      setServiceData({ ...serviceData, files: parsedFiles })

      try {
        // eslint-disable-next-line no-await-in-loop
        const result = await apiEndpoints.fileUpload.post('/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        parsedFiles.pop()
        parsedFiles.push({
          filename: file.name,
          content: result.data.fileURL
        })
        setServiceData({ ...serviceData, files: parsedFiles })
        addToast({
          title: 'Arquivo adicionado com sucesso!',
          type: 'success'
        })
      } catch {
        parsedFiles.pop()
        setServiceData({ ...serviceData, files: parsedFiles })
        addToast({
          title: 'Ocorreu um erro ao adicionar o arquivo. Tente novamente.',
          type: 'error'
        })
      }
    }

    setIsUploading(false)
  }

  useEffect(() => {
    if (serviceData?.files) {
      setFileCounter(serviceData.files.length)
    }
  }, [serviceData])

  return (
    <Container>
      {/* <aside>Arquivo (até 10Mb)</aside> */}
      <main>
        <Dropzone
          accept={{
            'text/plain': ['.doc', '.pdf', '.csv', '.xls', '.xlsx', '.docx']
          }}
          maxSize={1e+7}
          onDropAccepted={async (files) => { await handleOnUpload(files) }}
        >
          {({
            getRootProps, getInputProps, isDragActive, isDragReject
          }): any => (
            <DropContainer
              {...getRootProps()}
              isDragActive={isDragActive}
              isDragReject={isDragReject}
            >
              {!isUploading && (
                <>
                  <input {...getInputProps()} data-testid="upload" />
                  {renderDragMessage(isDragActive, isDragReject)}
                </>
              )}
              {isUploading && (
                <p>
                  enviando arquivo...
                </p>
              )}
            </DropContainer>
          )}
        </Dropzone>

        <LinkZone />

        <UploadCounter>
          {fileCounter}
          <aside>quantidade de anexos</aside>
        </UploadCounter>
      </main>
    </Container>
  )
}

export default Upload
