import styled, { css, keyframes } from 'styled-components'

export const Container = styled.section`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 90%;
  max-width: 500px;

  h2{
    color: #fff;
    font-weight: 500;
    text-align: center;
  }
`

export const BarsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 500px;
`

interface IProgressBar {
  isComplete: boolean
  isLoading: boolean
}

const loadingProgress = keyframes`
  0% { width: 0%; opacity: 1; }
  70%{ width: 100%; opacity: 1; }
  99%{ width: 100%; opacity: 0; }
  100%{ width: 0%; opacity: 0; }
`

export const ProgressBar = styled.div<IProgressBar>`
  width: 90%;
  max-width: 400px;
  height: 6px;
  background: #fff;
  border-radius: 50px;
  overflow: hidden;
  border: 2px solid #fff;
  margin: 0 5px;

  span{
    width: 0%;
    display: block;
    height: 100%;
    border-radius: 50px;
    background-color: #b776b2;
    background-image: linear-gradient(-140deg,#a5349c,#b776b2);
    color: #fff;
    
    ${(props) => props.isLoading && css`
      animation: ${loadingProgress};
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    `}

    ${(props) => props.isComplete && css`
      animation: none;
      width: 100%;
    `}
  }
`
