import React from 'react'

import Header from 'components/header'
import Footer from 'components/footer'
import AppProvider from 'hooks/appProvider'

import Quote from 'sections/Quote'
import ContactInfo from 'sections/ContactInfo'
import Sidebar from 'sections/Sidebar'

import Case from 'sections/Case'
import SalesPitch from 'sections/SalePitch'
import ContactFormCTA from 'sections/ContactFormCTA'
import OverlayForm from 'sections/OverlayForm'

import GlobalStyle from 'styles/GlobalStyles'
import { Container, QuoteContent } from 'styles/AppStyles'

const App: React.FC = () => (
  <AppProvider>
    <Header />
    <Container>
      <QuoteContent>
        <Quote />
        <ContactInfo />
        <Sidebar />
      </QuoteContent>
      <SalesPitch />
      <Case />
      <ContactFormCTA />
      <OverlayForm />
    </Container>
    <Footer />
    <GlobalStyle />
  </AppProvider>
)

export default App
