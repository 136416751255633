import React, {
  useCallback, useRef, useState
} from 'react'
import { FiInfo } from 'react-icons/fi'
import * as Yup from 'yup'

import { useQuoteData } from '../../../hooks/quoteData'
import { useToast } from '../../../hooks/toast'

import Tooltip from '../../Tooltip'

import {
  Container, PopUp, FormLike, ClosePopup, LineButton
} from './styles'

const LinkZone: React.FC = () => {
  const nameRef = useRef<HTMLInputElement>(null)
  const linkRef = useRef<HTMLInputElement>(null)
  const [popupVisibility, setPopupVisibility] = useState(false)

  const { addToast } = useToast()
  const { serviceData, setServiceData } = useQuoteData()

  const handleSubmit = useCallback(async () => {
    if (!nameRef.current?.value || !linkRef.current?.value) {
      addToast({
        title: 'preencha os dois campos e tente novamente',
        type: 'error'
      })
      return
    }

    let parsedFiles: any[] = []
    const formData = {
      name: nameRef.current.value,
      link: linkRef.current.value
    }

    if (serviceData?.files) {
      parsedFiles = serviceData.files
    }

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('este campo é obrigatório'),
        link: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, 'insira uma url válida')
      })

      await schema.validate(formData, {
        abortEarly: false
      })
    } catch {
      addToast({
        title: 'Erros no fomulário',
        message: 'o nome é obrigatório e a URL precisa ser completa (http)'
      })
      return
    }

    if (!parsedFiles.find((file) => file.filename === nameRef.current?.value)) {
      parsedFiles.push({
        filename: nameRef.current?.value,
        content: linkRef.current?.value
      })
      setServiceData({ ...serviceData, files: parsedFiles })
      addToast({
        title: 'Link adicionado',
        type: 'success'
      })
      setPopupVisibility(false)
    } else {
      addToast({
        title: 'Esse arquivo já foi adicionado',
        type: 'error'
      })
    }
  }, [serviceData, serviceData?.files])

  return (
    <Container>
      <LineButton type="button" onClick={() => { setPopupVisibility(!popupVisibility) }}>
        Adicionar links para arquivos
        <Tooltip title="compartilhe conosco seus arquivos diretamente da nuvem"><FiInfo size={15} /></Tooltip>
      </LineButton>

      <PopUp isVisible={popupVisibility}>
        <ClosePopup onClick={() => { setPopupVisibility(!popupVisibility) }}>X</ClosePopup>
        <FormLike>
          <input ref={nameRef} name="name" placeholder="Nome do arquivo" />
          <input ref={linkRef} name="link" placeholder="Link para o arquivo" />
          <LineButton type="button" onClick={handleSubmit}>Adicionar</LineButton>
        </FormLike>
      </PopUp>
    </Container>
  )
}

export default LinkZone
