import React from 'react'

import Office from '../../images/office.jpg'
import Dot from '../../images/forma-preta.png'
import { Container, Column, Heading, Text, QuarterMoonImage, KonektoDot } from './styles'

const SalesPitch: React.FC = () => {
  return (
    <Container>
      <Column width={'500px'}>
        <QuarterMoonImage url={Office} />
      </Column>
      <Column width={'50%'}>
        <KonektoDot src={Dot} />
        <Heading>Esqueça Fronteiras, falamos a sua lingua.</Heading>
        <Text>Tradução e localização são mais do que apenas passar um texto de uma língua para outra.</Text>
        <Text>O resultado afeta como você e sua empresa se apresentam ao mundo, como se comunicam com seus clientes, como seus manuais técnicos são compreendidos de um país para o outro e até questões jurídicas.</Text>
        <Text>Sabendo da importância desse serviço, a Konekto oferece um atendimento personalizado e diferenciado para atender às demandas específicas de cada projeto junto aos clientes.</Text>
      </Column>
    </Container>
  )
}

export default SalesPitch
