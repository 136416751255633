import styled, { css } from 'styled-components'

export const Container = styled.section<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #fff;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  display: flex;
  ${props => !props.isVisible && css`
    display: none;
  `}

  h3{
    margin: 0px 0px 30px;
    font-size: 24px;
    text-align: left;
    font-weight: 800;
    font-variation-settings: "slnt" 0,"wdth" 60,"GRAD" -100;
    letter-spacing: 1px;
    line-height: 1em;
    max-width: 650px;
    width: 90%;
  }

  form{
    display: flex; 
    flex-direction: column;
    row-gap: 11px;
    max-width: 650px;
    width: 90%;

    input, textarea{
      border-radius: 0px;
      padding: 4px 4px 14px;
      font-size: 16px;
      line-height: 1.6em;
      width: 100%;
      letter-spacing: 0px;
      border-bottom: 1px solid #000;
      margin-bottom: 0px;
      color: #000;
      letter-spacing: 1px;
      font-weight: 200;
      transition: all .3s;

      background: #fff;
      font-variation-settings: "slnt" 0,"wdth" 70,"GRAD" 0;

      &::placeholder{
        color: #777;
      }
    }

    textarea{
      height: 140px;
      padding: 13px 4px;
    }

    @media(max-width: 550px){
      row-gap: 5px;
      input{ padding: 6px 4px 9px; }
      textarea{ padding: 13px 4px; }
    }
  }
`

export const Row = styled.div`
  display: flex;
  column-gap: 15px;
  align-items: center;

  button{
    width: fit-content;
    padding: 8px 18px;
    background: none;
    border: 1px solid #000;
    font-weight: 400;
    font-size: 18px;
    font-variation-settings: "slnt" 0,"wdth" 150,"GRAD" -150;
    cursor: pointer;
    max-width: 120px;
    transition: all .3s;

    &:hover{
      background: #000;
      color: #fff;
    }
  }

  p{
    white-space: nowrap;
    margin: 0px;
  }

  @media(max-width: 550px){
    flex-direction: column;
    row-gap: 5px;

    button{
      max-width: 100%;
      width: 100%;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  border: none;
  background: none;
  cursor: pointer;

  span{
    font-size: 24px;
    font-weight: 1000;
  }

  @media(max-width: 550px){
    top: 20px;
    right: 10px;
  }
`
