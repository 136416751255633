import styled, { css } from 'styled-components'
import BGBody from '../images/bg-body.png'
import { colors } from './GlobalStyles'

export const Container = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url(${BGBody});
  background-size: 110%;
  background-repeat: repeat-y;
  background-position: 0 0;
`

export const QuoteContent = styled.section`
  width: 90%;
  max-width: 1000px;
  display: flex;
  padding: 40px 0 30px;
  flex-direction: column;

  @media (max-width: 550px) {
    padding: 20px 0;
  }
`

interface FormContainerProps {
  isVisible: boolean
}

export const FormContainer = styled.section<FormContainerProps>`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${colors.blue};
  background: linear-gradient(
    121deg,
    rgba(34, 118, 205, 1) 0%,
    rgba(88, 169, 211, 1) 100%
  );
  box-shadow: 0 6px 30px -12px #000;

  padding: 50px;
  border-radius: 3px;
  width: 100%;

  > h1 {
    color: #fff;
    align-self: flex-start;
    margin-bottom: 20px;
  }

  form {
    width: 100%;
  }

  > h1 + p {
    font-size: 20px;
    text-align: left;
    width: 100%;
    color: #fff;
    max-width: 100%;
    margin: -14px 0px 20px;
  }

  ${(props) =>
    props.isVisible &&
    css`
      display: flex;
    `}

  @media(max-width:550px) {
    padding: 20px;
  }
`

interface ISectionButtonProps {
  isActive?: boolean
}

export const SectionButton = styled.button<ISectionButtonProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 20px 8px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  line-height: 1.4em;
  letter-spacing: 2px;
  cursor: initial;
  position: relative;

  opacity: 0.4;

  &:focus {
    outline: none;
  }

  svg {
    display: block;
    position: relative;
    margin-left: 5px;
  }

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
      cursor: pointer;
    `}
`

export const GoBackButton = styled.button`
  align-self: flex-start;
  margin-bottom: 15px;
  background: #fff;
  padding: 11px 20px 10px;
  border: none;
  color: #3a71c4;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: 800;
  outline: none;
  cursor: pointer;
`

export const ProductCardStyles = css`
  padding: 30px 15px 10px;
  background: #fff;
  width: 100%;
  text-align: center;
  border: 1px solid ${colors.blue};
  color: #004990;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  h1 {
    font-size: 22px !important;
    font-weight: 700;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 0px;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: 0px;

    &.price-cut {
      text-decoration-line: line-through;
      font-size: 20px;
      font-weight: 500;
    }
  }
  h3 {
    margin: 0 0 3px;
    font-weight: 400;
    font-size: 13px;
  }

  > span {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 15px;
    min-height: 22px;
    letter-spacing: 0.5px;

    svg {
      margin-right: 6px;
      font-size: 16px;
    }
  }

  p {
    max-width: 350px;
    margin: 20px 0;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 1.4em;
  }

  div:not(.deadlineTooltip) {
    margin-top: 30px;
    width: 100%;
    border: none;
    background: #0861bd;
    color: #fff;
    padding: 15px 0;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 300;
  }

  div.deadlineTooltip {
    display: inline-block;
    margin-left: 3px;

    svg {
      vertical-align: middle;
      color: #c53333;
    }
  }

  @media (max-width: 550px) {
    margin-bottom: 10px;
  }
`

export const ExternalLink = styled.a`
  display: block;
  color: #fff;
  border: 2px solid #fff;
  text-decoration: none;

  width: 100%;
  padding: 15px 25px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s;

  &:hover {
    background: #fff;
    color: ${colors.blue};
  }
`

export const DefaultDocumentContainer = css`
  display: flex;
  flex-direction: column;

  > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 550px) {
    > section {
      flex-direction: column;
    }
  }
`
export const MarketingContent = styled.section`
  padding: 0;
`

export const PageContainer = styled.div``
export const PageHeading = styled.h2``
export const PageParagraph = styled.p``
