/* eslint-disable no-unused-vars */
import React, {
  createContext, useContext, useCallback, useState, useMemo
} from 'react'
import { v4 } from 'uuid'

import type { IProviderProps } from './provider.interface'
import ToastContainer from '../components/ToastContainer'

interface ToastContextData {
  addToast: (message: Omit<ToastMessage, 'id'>) => void
  removeToast: (id: string) => void
}

interface ToastMessage {
  id: string
  type?: 'info' | 'success' | 'error'
  title: string
  message?: string
}

const ToastContext = createContext<ToastContextData>({
  addToast: () => { },
  removeToast: () => { }
})

const ToastProvider: React.FC<IProviderProps> = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([])

  const addToast = useCallback(({ type, title, message }: Omit<ToastMessage, 'id'>) => {
    const id = v4()
    const toast = {
      id,
      type,
      title,
      message
    }
    setMessages((state) => [...state, toast])
  }, [])

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((state) => state.id !== id))
  }, [])

  const MemoToast = useMemo(() => ({ addToast, removeToast }), [])

  return (
    <ToastContext.Provider value={MemoToast}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  )
}

function useToast (): ToastContextData {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('Context must be used inside ToastContext.Provider')
  }
  return context
}

export { ToastProvider, useToast }
