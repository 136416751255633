import React, { useCallback, useEffect, useState } from 'react'
import { type IconType } from 'react-icons'
import { FiClock, FiInfo } from 'react-icons/fi'

import Tooltip from '../Tooltip'
import { useToast } from '../../hooks/toast'
import { useQuoteData } from '../../hooks/quoteData'
import { useCoupon } from '../../hooks/coupon'
import { formatCurrency } from '../../helpers/numberFormat'
import { calculateWordCost } from '../../helpers/quoteHelper'
import { TextbasedServices } from '../../helpers/services'

import {
  Container, ProductCard, DiscountTag, PriceCut
} from './styles'

interface IComponentProps {
  whenSelected: () => void
}

interface IProductCards {
  key: string
  name: string
  ref: string
  fullCost: number
  finalCost: number
  time: number
  description?: string
  discount?: number
  benefits?: Array<{
    benefit: string
    icon: IconType
  }>
  isSelected: boolean
}

const TextbasedProducts: React.FC<IComponentProps> = ({
  whenSelected
}) => {
  const { serviceData, setServiceData, setServiceTotals } = useQuoteData()
  const { couponData } = useCoupon()
  const [productCards, setProductCards] = useState<IProductCards[]>()
  const { addToast } = useToast()

  function generateProductCards (numberOfWords: number, languageGroup: number, discount: number = 0): void {
    let options
    if (serviceData?.selectedService) {
      if (serviceData.selectedService === 'translation') {
        if (languageGroup < 3) {
          options = TextbasedServices.translationBasic
        } else {
          options = TextbasedServices.translationAdvanced
        }
      }

      if (serviceData.selectedService === 'revision') {
        options = TextbasedServices.revision
      }

      const parsedOptions = options?.map((option) => {
        const [cost, discountedCost, time] = calculateWordCost({
          languageGroup,
          numberOfWords,
          serviceName: option.value,
          languageIsPtbr: serviceData.languageIsPtbr,
          discount
        })
        return {
          key: `${option.value}-${cost}`,
          name: option.name,
          ref: option.ref,
          fullCost: cost,
          finalCost: discountedCost,
          discount,
          time,
          description: option.description,
          benefits: option.benefits,
          isSelected: false
        }
      })

      setProductCards(parsedOptions)
      setServiceData({ ...serviceData, service: '' })
      setServiceTotals({ totalCost: undefined, totalTime: undefined })
    }
  }

  const handleClick = useCallback((prod: IProductCards) => {
    setServiceData({ ...serviceData, service: prod.name, ref: prod.ref })
    setServiceTotals({ totalCost: prod.finalCost, totalTime: prod.time })

    whenSelected()
    addToast({
      title: 'Revise seu pedido abaixo',
      type: 'info'
    })
  }, [serviceData])

  useEffect(() => {
    if (serviceData?.numberOfWords && serviceData.languageGroup) {
      generateProductCards(serviceData.numberOfWords, serviceData.languageGroup, couponData?.percent)
    } else {
      setProductCards([])
    }
  }, [serviceData?.languageFinal, serviceData?.languageMatrix, serviceData?.numberOfWords, couponData?.percent])

  return (
    <Container>
      {productCards?.map((product) => (
        <ProductCard
          key={product.key}
          isSelected={product.isSelected}
          onClick={() => { handleClick(product) }}
        >
          <h1>{product.name}</h1>
          {product.benefits?.map((benefit, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${product.key}-benefit-${index}`}>
              {benefit.icon({})}
              {' '}
              {benefit.benefit}
            </span>
          ))}
          <span>
            <FiClock />
            prazo estimado:
            {' '}
            {product.time}
            {' '}
            dias úteis
            <Tooltip title="o prazo de entrega dos pedidos feitos após as 16h são contados a partir do dia seguinte." className="deadlineTooltip">
              <FiInfo size={12} />
            </Tooltip>
          </span>
          {product.description && (
            <p>{product.description}</p>
          )}
          <h3>custo total:</h3>
          {product.finalCost !== product.fullCost && (
            <PriceCut>
              <h2 className="price-cut">
                R$
                {formatCurrency(product.fullCost)}
              </h2>
              <DiscountTag>
                -
                {product.discount}
                %
              </DiscountTag>
            </PriceCut>
          )}
          <h2>
            R$
            {formatCurrency(product.finalCost)}
          </h2>
          <div>peça agora</div>
        </ProductCard>
      ))}
    </Container>
  )
}

export default TextbasedProducts
