const ensureMinPrice = (value: number): number => (value < 50 ? 50 : value)
const applyDiscount = (value: number, percentage: number): number => value - Math.floor((value / 100) * percentage)

interface IWordRequestData {
  languageGroup: number
  serviceName: 'machineTranslation' | 'humanTranslation' | 'technicalTranslation' | 'simpleRevision' | 'technicalRevision'
  numberOfWords: number
  languageIsPtbr?: boolean
  discount?: number
}

interface IMinuteRequestData {
  languageGroup: number
  serviceName: 'machineTranscription' | 'humanTranscription' | 'captionFile' | 'captionHardcoded' | 'captionBonus'
  totalMinutes: number
  languageIsPtbr?: boolean
  discount?: number
}

export const calculateWordCost = (data: IWordRequestData): number[] => {
  const selectedGroup = wordServicesGroups[data.languageGroup - 1]
  const serviceData = selectedGroup[data.serviceName]

  if (!serviceData) {
    return [0, 0, 0]
  }
  let multiplier: number
  if (data.numberOfWords < 8000) {
    multiplier = serviceData.baseCost
  } else {
    multiplier = serviceData.thresholdCost
  }

  if (data.languageIsPtbr === false) {
    multiplier *= 2.2
  }

  const totalCost = Math.ceil(data.numberOfWords * multiplier * 1.3)
  const fullCost = ensureMinPrice(totalCost)
  const discountedPrice = (data.discount ? applyDiscount(totalCost, data.discount) : totalCost)
  const finalCost = ensureMinPrice(discountedPrice)

  const translationTime = Math.ceil(data.numberOfWords / serviceData.wordsPerDay)
  const totalTime = translationTime + serviceData.baseDays + 1

  return [fullCost, finalCost, totalTime]
}

export const calculateMinuteCost = (data: IMinuteRequestData): number[] => {
  const selectedGroup = minuteServicesGroups[data.languageGroup - 1]
  const serviceData = selectedGroup[data.serviceName]

  if (!serviceData) {
    return [0, 0, 0]
  }

  let multiplier: number
  if (data.totalMinutes < 60) {
    multiplier = serviceData.baseCost
  } else {
    multiplier = serviceData.thresholdCost
  }

  if (data.languageIsPtbr === false) {
    multiplier *= 2
  }

  const totalCost = Math.ceil(data.totalMinutes * multiplier * 1.3)
  const fullCost = ensureMinPrice(totalCost)
  const discountedPrice = (data.discount ? applyDiscount(totalCost, data.discount) : totalCost)
  const finalCost = ensureMinPrice(discountedPrice)

  const translationTime = Math.ceil(data.totalMinutes / serviceData.minutesPerDay)
  const totalTime = translationTime + serviceData.baseDays + 1

  return [fullCost, finalCost, totalTime]
}

// DATA FOR WORDS
const wordServicesGroups = [
  // GRUPO 1
  {
    machineTranslation: {
      wordsPerDay: 8000,
      baseDays: 2,
      baseCost: 0.15,
      thresholdCost: 0.14
    },
    humanTranslation: {
      wordsPerDay: 4000,
      baseDays: 2,
      baseCost: 0.19,
      thresholdCost: 0.17
    },
    technicalTranslation: {
      wordsPerDay: 4000,
      baseDays: 2,
      baseCost: 0.25,
      thresholdCost: 0.22
    },
    simpleRevision: {
      wordsPerDay: 8000,
      baseDays: 2,
      baseCost: 0.10,
      thresholdCost: 0.10
    },
    technicalRevision: {
      wordsPerDay: 6000,
      baseDays: 2,
      baseCost: 0.14,
      thresholdCost: 0.14
    }
  },
  // GRUPO 2
  {
    machineTranslation: {
      wordsPerDay: 7000,
      baseDays: 2,
      baseCost: 0.17,
      thresholdCost: 0.16
    },
    humanTranslation: {
      wordsPerDay: 3000,
      baseDays: 2,
      baseCost: 0.21,
      thresholdCost: 0.19
    },
    technicalTranslation: {
      wordsPerDay: 3000,
      baseDays: 2,
      baseCost: 0.27,
      thresholdCost: 0.24
    },
    simpleRevision: {
      wordsPerDay: 8000,
      baseDays: 2,
      baseCost: 0.12,
      thresholdCost: 0.12
    },
    technicalRevision: {
      wordsPerDay: 6000,
      baseDays: 2,
      baseCost: 0.16,
      thresholdCost: 0.16
    }
  },
  // GRUPO 3
  {
    humanTranslation: {
      wordsPerDay: 2000,
      baseDays: 3,
      baseCost: 0.55,
      thresholdCost: 0.50
    },
    technicalTranslation: {
      wordsPerDay: 2000,
      baseDays: 3,
      baseCost: 0.65,
      thresholdCost: 0.60
    },
    simpleRevision: {
      wordsPerDay: 5000,
      baseDays: 3,
      baseCost: 0.35,
      thresholdCost: 0.32
    },
    technicalRevision: {
      wordsPerDay: 4000,
      baseDays: 3,
      baseCost: 0.4,
      thresholdCost: 0.37
    }
  },
  // GRUPO 4
  {
    humanTranslation: {
      wordsPerDay: 2000,
      baseDays: 5,
      baseCost: 1.5,
      thresholdCost: 1.5
    },
    technicalTranslation: {
      wordsPerDay: 2000,
      baseDays: 5,
      baseCost: 1.7,
      thresholdCost: 1.7
    },
    simpleRevision: {
      wordsPerDay: 5000,
      baseDays: 5,
      baseCost: 0.8,
      thresholdCost: 0.8
    },
    technicalRevision: {
      wordsPerDay: 4000,
      baseDays: 5,
      baseCost: 0.9,
      thresholdCost: 0.9
    }
  }
]

// DATA FOR MINUTES
const minuteServicesGroups = [
  // GRUPO 1
  {
    machineTranscription: {
      minutesPerDay: 60,
      baseDays: 2,
      baseCost: 12,
      thresholdCost: 12
    },
    humanTranscription: {
      minutesPerDay: 30,
      baseDays: 2,
      baseCost: 15,
      thresholdCost: 15
    },
    captionFile: {
      minutesPerDay: 20,
      baseDays: 2,
      baseCost: 25,
      thresholdCost: 23
    },
    captionHardcoded: {
      minutesPerDay: 20,
      baseDays: 3,
      baseCost: 27,
      thresholdCost: 25
    },
    captionBonus: {
      minutesPerDay: 20,
      baseDays: 3,
      baseCost: 27,
      thresholdCost: 25
    }
  },
  // GRUPO 2
  {
    machineTranscription: {
      minutesPerDay: 60,
      baseDays: 2,
      baseCost: 14,
      thresholdCost: 14
    },
    humanTranscription: {
      minutesPerDay: 30,
      baseDays: 2,
      baseCost: 17,
      thresholdCost: 17
    },
    captionFile: {
      minutesPerDay: 20,
      baseDays: 2,
      baseCost: 27,
      thresholdCost: 25
    },
    captionHardcoded: {
      minutesPerDay: 20,
      baseDays: 3,
      baseCost: 30,
      thresholdCost: 28
    },
    captionBonus: {
      minutesPerDay: 20,
      baseDays: 3,
      baseCost: 30,
      thresholdCost: 28
    }
  },
  // GRUPO 3
  {
    captionFile: {
      minutesPerDay: 15,
      baseDays: 4,
      baseCost: 55,
      thresholdCost: 50
    },
    captionHardcoded: {
      minutesPerDay: 15,
      baseDays: 4,
      baseCost: 58,
      thresholdCost: 55
    },
    captionBonus: {
      minutesPerDay: 15,
      baseDays: 4,
      baseCost: 58,
      thresholdCost: 55
    }
  },
  // GRUPO 4
  {
    captionFile: {
      minutesPerDay: 15,
      baseDays: 5,
      baseCost: 140,
      thresholdCost: 140
    },
    captionHardcoded: {
      minutesPerDay: 15,
      baseDays: 5,
      baseCost: 140,
      thresholdCost: 140
    },
    captionBonus: {
      minutesPerDay: 15,
      baseDays: 5,
      baseCost: 140,
      thresholdCost: 140
    }
  }
]
