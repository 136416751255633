import styled, { css } from 'styled-components'
import { colors } from 'styles/GlobalStyles'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  > button{
    max-width: 350px;
  }
`

interface IPdfButtonProps {
  isActive: boolean
}

export const DownloadPdf = styled.button<IPdfButtonProps>`
  border: none;
  outline: none;
  background: none;
  text-decoration: underline;
  color: #fff;
  font-size: 17px;
  letter-spacing: 1px;
  font-weight: 300;
  opacity: .5;
  margin-left: 20px;

  ${(props) => props.isActive && css`
    opacity: 1;
    cursor: pointer;
  `}

  @media(max-width: 550px){
    display: none;
  }
`

interface IThankYou {
  isVisible: boolean
}

export const ThankYou = styled.div<IThankYou>`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #489ec7e6;
  z-index: 11;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  div{
    max-width: 600px;
    width: 90%;
    background: #fff;
    padding: 40px;
    position: relative;
  }

  button{
    position: absolute;
    top: -60px;
    left: 0px;
    border: none;
    outline: none;
    background: #fff;
    padding: 12px 26px;
    margin-right: 20px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: ${colors.blue};
    cursor: pointer;
  }

  p{
    margin: 0;
    text-align: left!important;
    max-width: 100%!important;
    font-size: 19px;
    letter-spacing: 1px;
    line-height: 1.4em;
  }

  h2{
    margin: 20px 0 0 0;
    font-size: 20px;
    font-weight: 500;
    color: ${colors.blue};
  }

  ${(props) => props.isVisible && css`
    display: flex;
  `}
`

interface ISubmitQuoteButton {
  isActive?: boolean
}

export const SubmitQuote = styled.button<ISubmitQuoteButton>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 20px 8px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  line-height: 1.4em;
  letter-spacing: 2px;
  cursor: initial;
  position: relative;

  opacity: .4;

  &:focus{
    outline:none;
  }

  svg{
    display: block;
    position: relative;
    margin-left: 5px;
  }

  &::after{
    display: none;
    content: 'Enviaremos um email com seu pedido e opções de pagamento';
    position: absolute;
    top: -65px;
    background: ${colors.pink};
    color: #fff;
    padding: 5px 20px;
    border-radius: 50px;
    letter-spacing: 1px;
    transform: translateX(80px);
  }


  ${(props) => props.isActive && css`
    opacity: 1;
    cursor: pointer;
    &:hover::after {
      display: block;
    }
  `}

  @media(max-width: 550px){
    &::after, svg{
      display:none!important;
    }
  }
`

interface ILoadingContainer {
  isVisible: boolean
}

export const LoadingContainer = styled.div<ILoadingContainer>`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #489ec7e6;
  z-index: 11;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${(props) => props.isVisible && css`
    display: flex;
  `}
`
