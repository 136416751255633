import styled, { css } from 'styled-components'

export const CEPContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div{
    max-width: 200px;
  }

  aside{
    margin: 0 0 17px 20px;
    background: #8999a9;
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    width: fit-content;
    font-size: 12px;
  }
`

export const DocumentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

interface IToggleProps {
  isOn: boolean
}

export const Toggle = styled.div<IToggleProps>`
  width: 80px;
  flex: none;
  margin: 0 5px 17px 0;
  height: 40px;
  border-radius: 30px;
  background: #8999a9;
  position: relative;
  cursor: pointer;

  &::before{
    content: '';
    width: 32px;
    height: 32px;
    top: 4px;
    left: 5%;

    position: absolute;
    background: #fff;
    border-radius: 50%;

    transition: left .3s;
  }

  ${(props) => props.isOn && css`
    &::before{
      left: 55%;
    }
  `}
`

export const ToggleLabel = styled.p<IToggleProps>`
  color: #ffffff;
  display: block;
  border-radius: 30px;
  padding: 7px 11px;
  white-space: nowrap;
  font-size: 12px;
  margin: 0 5px 17px 0px!important;

  div + &{
    margin-right: 5px;
  }

  ${(props) => props.isOn && css`
    background: #26e39f;
  `}
`
